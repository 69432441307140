import { FormData } from '../application/screens/Private/Users/components';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'postUsers';

export type StatusAccount = {
  note: string;
  status: boolean;
  userId: string;
};

export type ResponseAccountStatus = {
  message: string;
  status: boolean;
};

export const usePostAccount = (params: FormData, options: UseStubbornServiceOptions) => {
  return useStubbornService<ResponseAccountStatus>(SERVICE_ID, params, options);
};
