type MuiCssBaselineType = { [k: string]: any };
const rubikFont: MuiCssBaselineType = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  src: `
    local('Rubik'),
    url('../fonts/rubik/Rubik-VariableFont_wght.ttf') format('truetype')
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const MuiCssBaseline: MuiCssBaselineType = {
  styleOverrides: `
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    src: local('Rubik'), local('Rubik-Regular');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    ;
  }
`,
};

export default MuiCssBaseline;
