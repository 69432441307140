import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getCustomerQuestionnaires';

export interface Questionnaire {
  questionnaire_id: string;
  title: string;
  description: string;
  questionnaire_type: {
    type: string;
    id: string;
  };
}

export interface CustomerQuestionnaire {
  customer_questionnaire_id: string;
  questionnaire: Questionnaire;
  questionnaire_status: {
    questionnaire_status_id: number;
    status: string;
  };
}

export interface CustomerQuestionnaires {
  questionnaires: CustomerQuestionnaire[];
}

export const useGetCustomerQuestionnaires = (params: AnyObject, options: UseStubbornServiceOptions) => {
  return useStubbornService<CustomerQuestionnaires>(SERVICE_ID, params, options);
};
