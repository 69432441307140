// Palette
import palette from '../palette';

type MuiFabType = { [k: string]: any };

const MuiFab: MuiFabType = {
    root: {
        backgroundColor: palette.common.white,
        color: palette.text.secondary,
        '&:hover': {
            backgroundColor: palette.common.neutral,
        },
    },
};

export default MuiFab;
