import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, Typography } from '@mui/material';
import { Option } from './MenuStyles';
import { SIDEMENU_ID, SUBMENU_ID } from '../../../../../platform/permission/const';
import { usePermissionSideFilter } from '../../../../../lib/phinxapp/permission';
import palette from '../../../../../theme/palette';
import HomeIco from '../../../../assets/images/svgs/HomeIco';
import UserIco from '../../../../assets/images/svgs/UserIco';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@mui/icons-material/Settings';
import EventsIcon from '@mui/icons-material/Visibility';
import ListIcon from '@mui/icons-material/List';
import { ItemMenuDashboard } from './component/ItemMenuDashboard';

const sideMenuIcons = [
  {
    id: SIDEMENU_ID.DASHBOARD,
    title: 'Inicio',
    icon: <HomeIco />,
    path: '/dashboard',
  },
  {
    id: SIDEMENU_ID.USERS,
    title: 'Admin',
    icon: <UserIco />,
    path: '/users',
  },
  {
    id: SIDEMENU_ID.CUSTOMERS,
    title: 'Clientes',
    icon: <AssignmentIndIcon sx={{ color: 'grey', marginX: -0.3 }} />,
    path: '/customers',
  },
  {
    id: SIDEMENU_ID.TRANSACTIONS,
    title: 'Transacciones',
    icon: <ReceiptIcon sx={{ color: 'grey', marginX: -0.3 }} />,
    path: '/transactions',
  },
  {
    id: SIDEMENU_ID.EVENTS,
    title: 'Eventos',
    icon: <EventsIcon sx={{ color: 'grey', marginX: -0.3 }} />,
    path: '/events',
  },
  {
    id: SIDEMENU_ID.SETTING,
    title: 'Configuración',
    icon: <ReceiptIcon sx={{ color: 'grey', marginX: -0.3 }} />,
    path: '',
    dataSubMenu: [
      {
        id: SUBMENU_ID.SERVICE,
        title: 'Servicios',
        icon: <ListIcon sx={{ color: 'grey', marginX: -0.3 }} />,
        path: '/services',
      },
    ],
  },
];
export const Menu = () => {
  const menuItems = usePermissionSideFilter(sideMenuIcons);
  return (
    <>
      {menuItems.map((item, index) => {
        return item.dataSubMenu ? (
          <ItemMenuDashboard label={item.title} iconContainer={item.icon} subMenu={item.dataSubMenu} />
        ) : (
          <ListItem key={index} disableGutters>
            <NavLink to={item.path} color={palette.text.green} style={{ textDecoration: 'none' }}>
              <Option>
                {item.icon}
                <Typography color={palette.text.green} textTransform="capitalize" textAlign="left" style={{ textDecoration: 'none' }}>
                  {item.title}
                </Typography>
              </Option>
            </NavLink>
          </ListItem>
        );
      })}
    </>
  );
};
