import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport, GridRowModesModel, GridEventListener, MuiEvent, GridRowModes, GridRowModel } from '@mui/x-data-grid';
import { Alert, Grid, IconButton, Input, InputAdornment, Snackbar, ThemeProvider, Typography } from '@mui/material';
import { themeSearch } from '../../../../../theme/colors';
import { CisService, useUpdateCisService } from '../../../../../services';
import { buttonStyles, inputStyles } from '../../../../../theme/styles';
import { GridRowParams } from '@mui/x-data-grid';
import { GridRowId } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { ButtonCIS } from '../../../../components/button';
import { cisTemplates } from '../utils/templates';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} p={1} pb={0} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Campos
        </Typography>
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
      </Grid>
    </GridToolbarContainer>
  );
}

export const ServicesDatailsForm = ({ cisService }: { cisService?: CisService }) => {
  const [valueName, setValueName] = useState(cisService?.name);
  const [edit, setEdit] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { callCustomers: callUpdateCisService, error } = useUpdateCisService({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInputDisabled, setIsInputDisabled] = useState(true);

  const classes = inputStyles();

  const handleInputEditClick = () => {
    setIsInputDisabled(false);
    setEdit(false);
  };

  const handleInputSaveClick = () => {
    setIsDisabled(false);
    setIsInputDisabled(true);
    setEdit(true);
  };

  const handleInputCancelClick = () => {
    setValueName(cisService?.name);
    setIsInputDisabled(true);
    setEdit(true);
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setIsDisabled(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const callSendingData = async () => {
    try {
      await callUpdateCisService({ name: valueName, fields: [] });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const columnsServicesDatails: GridColDef[] = [
    {
      field: 'field_service_to',
      headerName: 'Campo Servicio',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_prop_from',
      headerName: 'Campo BR',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_type',
      headerName: 'Tipo',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: [
        { value: 'request', label: 'Request' },
        { value: 'response', label: 'Response' },
      ],
    },
    {
      field: 'data_type',
      headerName: 'Dato',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: [
        { value: 'string', label: 'String' },
        { value: 'number', label: 'Number' },
        { value: 'boolean', label: 'Boolean' },
      ],
    },
    {
      field: 'form_template_id',
      headerName: 'Template',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: cisTemplates.map((x) => ({ value: x.form_template_id, label: x.name })),
    },
    {
      field: 'form_field_id',
      headerName: 'FormFieldID',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_id',
      headerName: 'FieldID',
      editable: true,
      flex: 1,
    },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} sx={{ color: 'green' }} />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} sx={{ color: 'red' }} />,
        ];
      },
    },
  ];

  return (
    <>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} mb={4} display={'flex'} alignItems={'baseline'} gap={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Nombre:
          </Typography>
          <Input
            sx={{ minWidth: 300 }}
            type={'string'}
            value={valueName}
            onChange={(e) => setValueName(e.target.value)}
            disabled={isInputDisabled}
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                {edit ? (
                  <IconButton onClick={handleInputEditClick}>
                    <EditIcon sx={{ color: '#000000' }} />
                  </IconButton>
                ) : (
                  <Grid>
                    <IconButton onClick={handleInputSaveClick} sx={{ padding: 0 }}>
                      <SaveIcon sx={{ color: 'green' }} />
                    </IconButton>
                    <IconButton onClick={handleInputCancelClick} sx={{ padding: 0 }}>
                      <CancelIcon sx={{ color: '#000000' }} />
                    </IconButton>
                  </Grid>
                )}
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <Box
            sx={{
              height: 500,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <DataGrid
              localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
              rows={cisService?.fields_map || []}
              columns={columnsServicesDatails}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
            <Grid item xs={12} display={'flex'} alignItems={'center'} mt={4} pb={4}>
              {error ? (
                <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    No se guardo correctamente, intente nuevamente.
                  </Alert>
                </Snackbar>
              ) : (
                <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Guardado correctamente
                  </Alert>
                </Snackbar>
              )}
              <ButtonCIS typeButton="submit" textButton="Guardar" handleOnClick={callSendingData} disabled={isDisabled} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
