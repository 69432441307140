import palette from '../palette';

type MuiListItemIconType = { [k: string]: any };

const MuiListItemIcon: MuiListItemIconType = {
    root: {
        color: palette.text.secondary,
    },
};

export default MuiListItemIcon;
