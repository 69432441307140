import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const GET_CIS_MAPPING_SERVICE_ID = 'getCisMapping';

export interface GetCisMappingResponse {
  cis_map: GetCisMapping[];
}

export type GetCisMapping = {
  cis_service_id: string;
  service_name: string;
  field_service_to: string;
  field_type: string;
  data_type: string;
  form_template_id: number;
  field_id: number;
  field_prop_from: string;
  form_field_id: number;
  cis_field_map_id: string;
  cis_service_field_id: string;
  cis_service_form_template_id: string;
};

export const useGetCisMappingService = (params = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<GetCisMappingResponse>(GET_CIS_MAPPING_SERVICE_ID, params, options);
};
