import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getCustomerQuestionnaireResume';

export interface Answer {
  title: string;
  description: string;
  created_at: Date;
  answer: {
    raw: AnyObject;
    logicalValue: AnyObject;
  };
}

export interface NextQuestion {
  title: string;
  description: string;
  created_at: Date;
  order: number;
  next: number;
}

export interface QuestionnaireResume {
  answers: Answer[];
  next_question: NextQuestion | null;
  total_questions: number;
}

export interface CustomerQuestionnaireResume {
  questionnaire: QuestionnaireResume;
}

export const useGetCustomerQuestionnaireResume = (params: AnyObject, options: UseStubbornServiceOptions) => {
  return useStubbornService<CustomerQuestionnaireResume>(SERVICE_ID, params, options);
};
