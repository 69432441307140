import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import { useStyles } from '../../../../../../theme/styles';
import { primary } from '../../../../../../theme/colors';


type AnyObject = { [k: string]: any };

export function EnhancedTableHead({ order, orderBy, onRequestSort, headCells }: AnyObject) {
  const classes = useStyles();

  const createSortHandler = (property: any) => (event: AnyObject) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow  >
        {headCells.map(({ id, label, icon, render, orderById, noSort, hideSortIcon, ...styles }: AnyObject) => (
          <TableCell
            style={{ background: primary.white}}
            key={id}
            align="left"
            sortDirection={orderBy === id ? order : false}
            {...styles}
            classes={{
              root: classes.root,
            }}
          >
            <TableSortLabel
              active={orderBy === id || orderBy === orderById}
              direction={orderBy === id || orderBy === orderById ? order : 'asc'}
              onClick={!noSort ? createSortHandler(orderById || id) : undefined}
              IconComponent={!hideSortIcon ? StyledUnfoldMoreIcon : EmptyComponent}
            >
              {icon ? icon() : label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EmptyComponent = () => <></>;

const StyledUnfoldMoreIcon = () => <UnfoldMore htmlColor={'#b5bcc9'} />;
