import React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { ButtonCIS } from '../../../../components/button';
import { Grid, ThemeProvider, Typography } from '@mui/material';
import { themeSearch } from '../../../../../theme/colors';
import { CisService } from '../../../../../services';
import { cisTemplates } from '../../ServiceCreate/components/ServiceCreateTable';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

function CustomToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, fieldName: '', type: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid item xs={12} p={1} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Campos
        </Typography>
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
      <Grid item xs={12} pr={1} pl={1}>
        <ButtonCIS textButton="+ Agregar campo" handleOnClick={handleClick} />
      </Grid>
    </GridToolbarContainer>
  );
}

export const UpdateServiceTable = ({ cisService }: { cisService?: CisService }) => {
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    console.log('asd');
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'field_service_to',
      headerName: 'Campo Servicio',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_prop_from',
      headerName: 'Campo BR',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_type',
      headerName: 'Tipo',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: [
        { value: 'request', label: 'Request' },
        { value: 'response', label: 'Response' },
      ],
    },
    {
      field: 'data_type',
      headerName: 'Dato',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: [
        { value: 'string', label: 'String' },
        { value: 'number', label: 'Number' },
        { value: 'boolean', label: 'Boolean' },
      ],
    },
    {
      field: 'form_template_id',
      headerName: 'Template',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueOptions: cisTemplates.map((x) => ({ value: x.form_template_id, label: x.name })),
    },
    {
      field: 'form_field_id',
      headerName: 'FormFieldID',
      editable: true,
      flex: 1,
    },
    {
      field: 'field_id',
      headerName: 'FieldID',
      editable: true,
      flex: 1,
    },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} sx={{ color: 'green' }} />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} sx={{ color: 'red' }} />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 700,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={cisService?.fields_map || []}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
};
