import React, { ComponentClass, Fragment } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { MenuDivider, MenuItem } from '../profiles/types';

const buildRoute = (prefix: string, path: string) => {
    const route = prefix + path;
    return route.replaceAll('//', '/');
};

const buildSubRoutes = (elem: MenuItem | MenuDivider, prefix: string): {path: string, component: React.FC | ComponentClass, default: boolean}[] => {
    const elements = [];
    if (!(elem as MenuDivider).divider) {
        const item = elem as MenuItem;
        if (item.target?.path) {
            elements.push({path: buildRoute(prefix, item.target?.path || ''), component: item.target?.component || Fragment, default: Boolean(item.default)});
        }
        if (item.children.length) {
            elements.push(...item.children.map((elem) => buildSubRoutes(elem, buildRoute(prefix, item.target?.path || ''))).flat(1));
        }
    }
    // const flatElements = elements.flat(Infinity);
    return elements;
};

export const MenuRoutes = React.memo(({
    config,
    prefix = '',
}: {
  config: MenuItem[];
  prefix?: string;
}) => {
    // const { activeRules } = usePermissionContext();

    // const filterConfig = useMemo(() => {
    //   const innerConfig: MenuItem[] = [];
    //   config.forEach((item) => {
    //     if (
    //       !(
    //         activeRules.routes.filter((_item) => _item.key === item.key).length >
    //         0
    //       )
    //     ) {
    //       innerConfig.push({ ...item });
    //     }
    //   });
    //   return innerConfig;
    // }, [activeRules, config]);

    if (!config.length) {
        return null;
    }

    return (
        <Routes>
            {
                config.map((item) => {
                    const path = buildRoute(prefix, item.target?.path || '');
                    const routes = buildSubRoutes(item, path);
                    return <Fragment key={item.key}>
                        {routes.map((elem) => {
                            if (!elem.component) {
                                return <Fragment/>;
                            }
                            const Comp: any = elem.component;
                            return <Fragment key={elem.path}>
                                <Route path={elem.path} element={<Comp />} />
                                {elem.default ? <Route path={buildRoute(elem.path, '/*')} element={<Navigate to={elem.path} />} /> : null}
                            </Fragment>;
                        })}
                    </Fragment>;
                })
            }
        </Routes>
    );
});
