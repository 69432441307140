import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../assets/Loading';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { useGetUserById } from '../../../../services';

export const UserDetails = () => {
  const { id } = useParams();
  const { data, working } = useGetUserById({ id: id }, { autoCall: true });
  return working ? (
    <Grid container height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Loading viewBox="0 0 100 100" width="150px" height="150px" stroke="#FAC306" />
    </Grid>
  ) : (
    <Box width="100%" height="100%" paddingX={4}>
      <Grid container direction="column">
        <Grid item>
          <HeaderTable title="Detalles de usuario" />
        </Grid>
        <Accordion style={{ marginBottom: 10 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20 }}>Información de cuenta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Usuario ID:</Typography>
                <Typography>{data?.bo_user.bo_user_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cuenta ID:</Typography>
                <Typography>{data?.bo_user.account_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black', marginLeft: 10 }}>Fecha de creación:</Typography>
                <Typography>{moment(data?.bo_user.created_at).format('D-MM-YYYY')}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Nombre completo:</Typography>
                <Typography>{`${data?.bo_user.first_name} ${data?.bo_user.last_name}`}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Box>
  );
};
