// Colors
import { grey } from '../colors';
import palette from '../palette';

type MuiFilledInputType = { [k: string]: any };

const MuiFilledInput: MuiFilledInputType = {
    root: {
        backgroundColor: palette.background.default,
        '&:hover': {
            backgroundColor: grey.background,
        },
        '&$focused': {
            backgroundColor: grey.background,
        },
    },
};

export default MuiFilledInput;
