import React, {createContext, ReactNode, ReactNodeArray, useContext, useEffect, useMemo, useState} from 'react';
import {Configuration, StubbornServer} from '../StubbornServer';

export interface StubbornServerContextValue {
  SBServer: StubbornServer;
  isReady: boolean;
}

export const StubbornServerContext = createContext<StubbornServerContextValue>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    SBServer: null,
    isReady: false,
});

export interface StubbornServerContextProviderProps {
  children: ReactNode | ReactNodeArray;
  configuration: Configuration;
}

export const StubbornServerContextProvider = ({configuration, children}: StubbornServerContextProviderProps) => {
    const [isReady, setIsReady] = useState(false);
    const [SBServer, setSBServer] = useState<StubbornServer | null>(null);

    useEffect(() => {
        (async () => {
            setIsReady(false);
            setSBServer(await StubbornServer.create(configuration));
            setIsReady(true);
        })();
    }, [configuration]);

    useEffect(() => {
        if (SBServer) {
            SBServer.status();
        }
    }, [SBServer]);

    const value = useMemo<StubbornServerContextValue>(
        () => ({
            SBServer: SBServer as StubbornServer,
            isReady,
        }),
        [SBServer, isReady],
    );
    return <StubbornServerContext.Provider value={value}>{!isReady ? null : children}</StubbornServerContext.Provider>;
};

export const useStubbornServerContext = () => useContext<StubbornServerContextValue>(StubbornServerContext);
