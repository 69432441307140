import {SessionConfiguration, StubbornHttpService} from './StubbornHttpService';
import {ExecuteResponse} from '../HttpService';
import {TokenManager} from './TokenManager';

export interface Configuration {
    url: string;
    sessions?: SessionConfiguration[];
}

export interface AnyObject<T = any> {
    [k: string]: T;
}

export type QueryObject = {
    [k: string]: string | number;
};

export const getQueryString = (query: QueryObject) =>
    Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

const defaultConfigurations: Required<Omit<Configuration, 'url'>> = {
    sessions: [{name: 'default', header: 'authorization'}],
};

export interface Session {
    isGuest: boolean;
    data: null | {[k: string]: any};
    name: string;
}

export type sessionChangeFunction = (session: Session) => void;

// TODO: aplicar patrones de estrategia para la Session
export class StubbornServer {
    configuration: Required<Configuration>;
    protected http?: StubbornHttpService;
    protected sessionListeners: Array<sessionChangeFunction> = [];
    protected sessionsInfo: Map<string, Session> = new Map<string, Session>();

    constructor(configuration: Configuration) {
        this.configuration = {
            ...defaultConfigurations,
            ...configuration,
        };
    }

    get Http(): StubbornHttpService {
        return this.http as StubbornHttpService;
    }

    set Http(http: StubbornHttpService) {
        this.http = http;
    }

    static async create(configuration: Configuration) {
        const instance = new StubbornServer(configuration);
        instance.Http = await StubbornHttpService.create('fetch', {baseUrl: instance.configuration.url, sessions: instance.configuration.sessions});
        instance.Http.service.onSuccess = instance.onSuccess.bind(instance);
        //@ts-ignore
        instance.Http.service.onError = instance.onError.bind(instance);
        return instance;
    }

    set TokenManager(manager: TokenManager) {
        this.Http.TokenManager = manager;
    }

    async onSuccess(response: ExecuteResponse<any>) {
        for (const item of this.configuration.sessions) {
            //@ts-ignore
            const header = response.headers.get(item.header.toLowerCase());
            if (header) {
                let [, token] = header.split(' ');
                let name = 'default';
                if (token.includes(':')) {
                    [name, token] = token.split(':');
                }
                if (token) {
                    await this.Http.addSessionToken(name, header);
                }
            }
        }
    }

    async onError(response: ExecuteResponse<any>) {
        if (response.statusCode === 403 || response.statusCode === 401 || response.statusCode === 412) {
            await this.Http.clearSessionTokens();
            this.clearSessionInfo()
        }
    }

    callService<T, S extends AnyObject = AnyObject>(name: string, params: S): Promise<ExecuteResponse<T>> {
        return this.Http.service.post<T>(`/service/${name}`, params);
    }

    getSessionInfo(name: string) {
        return this.sessionsInfo.get(name);
    }

    setSessionInfo(name: string, session: Session) {
        this.sessionsInfo.set(name, session);
        this.emitSessionChange(name);
    }

    removeSessionInfo(name: string) {
        this.sessionsInfo.delete(name);
        this.emitSessionChange(name);
    }

    clearSessionInfo() {
        const names = this.sessionsInfo.keys();
        for (const name of names) {
            this.emitSessionChange(name);
        }
        this.sessionsInfo.clear();
    }

    subscribe(event: 'SessionChange', fn: sessionChangeFunction) {
        if (event === 'SessionChange') {
            this.sessionListeners.push(fn);
            return () => {
                this.sessionListeners = this.sessionListeners.filter(item => item !== fn);
            };
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }

    emitSessionChange(name: string) {
        const session = this.sessionsInfo.get(name);
        const sessionGuest: Session = {
            isGuest: true,
            name,
            data: null,
        };
        this.sessionListeners.forEach(fn => {
            fn(session || sessionGuest);
        });
    }

    async auth<T extends AnyObject = AnyObject>(credentials: AnyObject, name = 'default') {
        const response = await this.Http.service.post<T>(`/auth/${name}`, credentials);
        const {__session, ...data} = response.data as T;
        const session: Session = {
            isGuest: false,
            data,
            name: __session,
        };
        this.setSessionInfo(__session, session);
        return response;
    }

    async logout() {
        await this.Http.clearSessionTokens();
        // await auth().signOut();
        this.clearSessionInfo();
    }

    async status() {
        const response = await this.Http.service.get<AnyObject>('/auth/status');
        if (response.data) {
            for (const {name} of this.configuration.sessions) {
                const data = response.data[name];
                const session: Session = {
                    isGuest: false,
                    data,
                    name,
                };
                this.setSessionInfo(name, session);
            }
        }
        return response;
    }
}
