import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getUserById';

export interface UserByIdResponse {
  bo_user: UserDetails;
}

export type UserDetails = {
  account_id: string;
  bo_user_id: string;
  created_at: string;
  first_name: string;
  last_name: string;
};

export interface UserByIdRequest {
  id?: string;
}

export const useGetUserById = (params: UserByIdRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<UserByIdResponse>(SERVICE_ID, params, options);
};
