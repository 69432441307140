export const cisTemplates = [
  {
    form_template_id: 1,
    name: 'Register User',
  },
  {
    form_template_id: 2,
    name: 'Onboarding customer',
  },
  {
    form_template_id: 3,
    name: 'On Boarding Perfil - General',
  },
  {
    form_template_id: 4,
    name: 'On Boarding Perfil - Argentina',
  },
  {
    form_template_id: 5,
    name: 'On Boarding Perfil - Bolivia',
  },
  {
    form_template_id: 6,
    name: 'On Boarding Perfil - Chile',
  },
  {
    form_template_id: 7,
    name: 'On Boarding Perfil - Ecuador',
  },
  {
    form_template_id: 8,
    name: 'On Boarding Perfil - Paraguay',
  },
  {
    form_template_id: 9,
    name: 'Envío de Remesa WU - Argentina"',
  },
  {
    form_template_id: 10,
    name: 'Envío de Remesa WU - Bolivia',
  },
  {
    form_template_id: 11,
    name: 'Envío de Remesa WU - Chile',
  },
  {
    form_template_id: 12,
    name: 'Envío de Remesa WU - Ecuador',
  },
  {
    form_template_id: 13,
    name: 'Envío de Remesa WU - Paraguay',
  },
  {
    form_template_id: 19,
    name: 'Pago de Remesa WU - Argentina',
  },
  {
    form_template_id: 20,
    name: 'Pago de Remesa WU - Bolivia',
  },
  {
    form_template_id: 21,
    name: 'Pago de Remesa WU - Chile',
  },
  {
    form_template_id: 22,
    name: 'Pago de Remesa WU - Ecuador',
  },
  {
    form_template_id: 23,
    name: 'Pago de Remesa WU - Paraguay',
  },
  {
    form_template_id: 29,
    name: 'Stage & Pay WU - Argentina',
  },
  {
    form_template_id: 30,
    name: 'Stage & Pay WU - Bolivia',
  },
  {
    form_template_id: 31,
    name: 'Stage & Pay WU - Chile',
  },
  {
    form_template_id: 32,
    name: 'Stage & Pay WU - Ecuador',
  },
  {
    form_template_id: 33,
    name: 'Stage & Pay WU - Paraguay',
  },
];
