import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const DELETE_CIS_MAPPING_ID = 'deleteCisMapping';

export interface DeleteCisMappingResponse {
  fields: DeleteCisMapping[];
}

export type DeleteCisMapping = {
  cis_field_map_id: string;
  cis_service_id: string;
  cis_service_field_id: string;
  cis_service_form_template_id: string;
};

export interface DeleteCisMappingRequest {
  cis_field_map_id: string;
}

export const useDeleteCisMappingService = (params: DeleteCisMappingRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<DeleteCisMappingResponse>(DELETE_CIS_MAPPING_ID, params, options);
};
