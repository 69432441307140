import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const CREATE_CIS_SERVICE_ID = 'createCisService';

export interface CreateCisServiceRequest {
  name: string;
  // templates: number[];
  fields: {
    field_service_to: string;
    field_type: string;
    data_type: string;
    form_template_id: number;
    form_field_id: number;
    field_prop_from: string;
    field_id: number;
  }[];
}

export interface CisService {
  cis_service_id: string;
  name: string;
  // templates: {
  //   cis_service_form_template_id: string;
  //   name: string;
  //   form_tempate_id: number;
  // }[];
  fields_map: {
    id: string;
    cis_service_field_id: string;
    field_service_to: string;
    field_type: string;
    data_type: string;
    cis_field_map_id: string;
    form_template_id: number;
    field_id: number;
    field_prop_from: string;
    form_field_id: number;
    template_name: number;
  }[];
  created_at: Date;
  updated_at: Date;
}

export interface CreateCisServiceResponse {
  cis_service: CisService;
}

export const useCreateCisService = (params: CreateCisServiceRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<CreateCisServiceResponse>(CREATE_CIS_SERVICE_ID, params, options);
};
