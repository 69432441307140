import { routes } from './routes';
import {MenuItem} from '../../../../lib/phinxapp/profiles/types';
import { Users } from '../../../../application/screens/Private';

export const usersMenu: MenuItem = {
    key: 'usersMenu',
    basePath: '/users',
    target:{path: '/',component: Users},
    enabled: true,
    icon: 'List',
    text: 'users',
    children: routes,
    default: true,
};
