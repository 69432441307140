import { makeStyles } from '@mui/styles';

export const InfoTableStyles = makeStyles({
  root: {
    width: '100%',
    '& > MuiTableCell-head': {
      backgroundColor: '#FD0000',
    }
  },
  tableContainer: {
    maxHeight: '800px',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#b5b5b5',
      borderRadius: 2,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
  },
  caption: {
    height: '15px',
  },
  typography: {
    fontSize: 13,
    color: '#4A4A4A',
    textOverflow: 'true',
  },
  arrayContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  seccionNames: {
    '&:hover': {
      fontWeight: 600,
    },
  },
  countContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: 'center',
    boxSizing: 'border-box',
    
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 2,
    },
  },
});
