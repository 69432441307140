import { Users, UserDetails, MyAccount } from '../../../../application/screens/Private';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'users',
    basePath: '/',
    target: {
      component: Users,
      path: '/users',
    },
    enabled: true,
    icon: 'List',
    text: 'Users',
    children: [],
    default: true,
  },
  {
    key: 'user-details',
    basePath: '/',
    target: {
      component: UserDetails,
      path: '/users/:id',
    },
    enabled: true,
    icon: 'List',
    text: 'User Information',
    children: [],
    default: false,
  },
  {
    key: 'my-account',
    basePath: '/',
    target: {
      component: MyAccount,
      path: '/my-account',
    },
    enabled: true,
    icon: 'List',
    text: 'My account',
    children: [],
    default: false,
  },
];
