// this duplication it's to decouple with the rest of the application
interface AnyObject {
    [k: string]: unknown;
}

export interface HttpServiceConfig {
    baseUrl?: string;
    headers?: AnyObject;
}

export interface ExecuteOptions {
    body?: AnyObject;
   headers?: AnyObject;
}

export interface ExecuteResponse<T> {
    data: T | null | undefined;
    statusCode: number;
    headers: AnyObject;
    error?: unknown;
}

export enum METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export const defaultHttpServiceConfig: HttpServiceConfig = {
    baseUrl: '',
    headers: {},
};

export abstract class HttpService {
    protected config: HttpServiceConfig;
    onSuccess?: <T = unknown>(response: ExecuteResponse<T>) => void | Promise<void>;
    onError?: <T = unknown>(response: ExecuteResponse<T> | unknown) => void | Promise<void>;

    constructor(initialConfig?: Partial<HttpServiceConfig>) {
        this.config = {...defaultHttpServiceConfig, ...initialConfig};
    }

    set Headers(headers: AnyObject) {
        this.config.headers = headers;
    }

    get Headers() {
        return this.config.headers || {};
    }

    protected abstract doCall<T = unknown>(method: METHOD, url: string, options?: ExecuteOptions): Promise<ExecuteResponse<T>>;

    async execute<T = unknown>(method: METHOD, url: string, options?: ExecuteOptions): Promise<ExecuteResponse<T>> {
        try {
            const response = await this.doCall<T>(method, url, options);
            if (this.onSuccess) {
                await this.onSuccess(response);
            }
            return response;
        } catch (e) {
            if (this.onError) {
                await this.onError(e);
            }
            throw e;
        }
    }

    async get<T = unknown>(url: string, options?: ExecuteOptions) {
        return this.execute<T>(METHOD.GET, url, options);
    }

    async post<T = unknown>(url: string, body: AnyObject, options?: Omit<ExecuteOptions, 'body'>) {
        return this.execute<T>(METHOD.POST, url, options ? {...options, body} : {body});
    }

    async put<T = unknown>(url: string, body: AnyObject, options?: Omit<ExecuteOptions, 'body'>) {
        return this.execute<T>(METHOD.PUT, url, options ? {...options, body} : {body});
    }

    async patch<T = unknown>(url: string, body: AnyObject, options?: Omit<ExecuteOptions, 'body'>) {
        return this.execute<T>(METHOD.PATCH, url, options ? {...options, body} : {body});
    }

    async delete<T = unknown>(url: string, body: AnyObject, options?: Omit<ExecuteOptions, 'body'>) {
        return this.execute<T>(METHOD.DELETE, url, options ? {...options, body} : {body});
    }
}
