import { useMediaQuery } from '@mui/material';
import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useProfileContext } from '../../lib/phinxapp/profiles/ProfileContext';
import { MenuCollection } from '../../lib/phinxapp/profiles/types';
import { useStubbornAuth, useStubbornServerContext } from '../../lib/StubbornService/react';
import { MemoryTokenManager, RNTokenManager, TokenManager } from '../../lib/StubbornService/TokenManager';
import { MenuLayout } from './MenuLayout';
import { ResponsiveMenuLayout } from './ResponsiveLayout';

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const MainLayout: FunctionComponent<MainLayoutProps> = React.memo(({ children }) => {
  const SBAuth = useStubbornAuth();

  const { SBServer } = useStubbornServerContext();
  const session = SBServer.getSessionInfo('default');
  const location = useLocation();

  const pepe = useCallback(async () => {
    const res = await SBAuth.status();
  }, [SBAuth]);

  useEffect(() => {
    pepe();
  }, [location.key, pepe]);

  const matches = useMediaQuery('(max-width:750px)');

  return matches ? <ResponsiveMenuLayout children={children} /> : <MenuLayout children={children} />;
});
