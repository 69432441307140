import { Transactions } from '../../../../application/screens/Private';
import { TransactionsDetails } from '../../../../application/screens/Private/TransactionsDetails';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'transactions',
    basePath: '/',
    target: {
      component: Transactions,
      path: '/transactions',
    },
    enabled: true,
    icon: 'List',
    text: 'Listado de Transacciones',
    children: [],
    default: true,
  },
  {
    key: 'transactions-details',
    basePath: '/',
    target: {
      component: TransactionsDetails,
      path: '/transactions/:id',
    },
    enabled: true,
    icon: 'List',
    text: 'Información',
    children: [],
    default: false,
  },
];
