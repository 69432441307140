import { TransactionsObj } from '../types/transactionTypes';

export const typeTransaction = (item: TransactionsObj) => {
  if (item.transactionType.name == 'envio_remesa') {
    return 'Envío de remesa';
  }
  if (item.transactionType.name == 'recibo_remesa') {
    return 'Recibe remesa';
  }
  if (item.transactionType.name == 'envio_remesa_sp') {
    return 'Envío de remesa SP';
  }
  if (item.transactionType.name == 'sp_remesa_cis') {
    return 'Recibe remesa SP';
  }
  if (item.transactionType.name == 'envio_remesa_cis') {
    return 'Envío de remesa CIS';
  }
  if (item.transactionType.name == 'recibo_remesa_cis') {
    return 'Recibe remesa CIS';
  }
};
