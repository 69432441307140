import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
};

type LineChartProp = {
  data: TmpData;
}

type DataSetItem = {
  label: string,
  data: number[],
  borderColor?: string,
  backgroundColor?: string,
}

type TmpData = {
  datasets: DataSetItem[],
  labels: string[]
} 

export function LineChart({ data }: LineChartProp){ 
  return <Line options={defaultOptions} data={{
    ...data,
    datasets:data.datasets.map((item: DataSetItem) => {
      return {
        ...item,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    })
  }}/>
}