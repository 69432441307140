import { makeStyles } from '@mui/styles';
import { grey, primary } from '../colors';
import typography from '../typography';

export const buttonStyles = makeStyles(() => ({
  button: {
    background: primary.yellowCis,
    display: 'flex',
    color: '#000000',
    fontWeight: 'normal',
    fontFamily: typography.fontFamily,
    fontSize: 15,
    textTransform: 'none',
    padding: '8px 16px',
    borderRadius: 4,
    '&:disabled': {
      color: '#000000',
      background: primary.disabled,
    },
    '&:hover': {
      background: primary.yellowCis,
      color: '#000000',
    },
  },
  buttonDelete: {
    background: primary.error,
    display: 'flex',
    color: 'white',
    fontWeight: 'normal',
    fontFamily: typography.fontFamily,
    fontSize: 15,
    textTransform: 'none',
    padding: '8px 16px',
    borderRadius: 4,
    '&:disabled': {
      color: 'white',
      background: primary.error,
    },
    '&:hover': {
      background: primary.error,
    },
  },
  buttonOperations: {
    background: primary.yellowCis,
    color: '#000000',
    borderRadius: 4,
    '&:disabled': {
      color: '#000000',
      background: grey.textSecondary,
    },
    '&:hover': {
      background: primary.yellowCis,
      color: '#000000',
    },
  },
}));
