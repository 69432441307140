import { Button, FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import { Fragment, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { BoUser } from '../../../../../services';

export type StatusUsersCISProps = {
    id?: string;
  user: BoUser;
  handleSubmit: any;
  handleCancel: any;
};

export type FormData = {
  note:string,
  status: boolean,
  userId: string,
}

export const StatusUsersCIS = ({ id = `modal_${uuidv4()}`, user, handleSubmit, handleCancel }: StatusUsersCISProps) => {
  const [formData, setFormData] = useState<FormData>({
    note: ``,
    status: false,
    userId: user.bo_user_id,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      ...formData,
      note: e.target.value
    };
    setFormData(data)
  }

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      ...formData,
      status:e.target.checked
    }
    setFormData(data)
  }

  const handleSubmitFormData = () => {
    handleSubmit(formData.note && formData.userId? formData : null);
  }

    return <Fragment>
      <FormGroup>
        <TextField
          id="outlined-multiline-flexible"
          label="Por que?"
          multiline
          maxRows={4}
          value={formData.note}
          onChange={handleChange}
        />
        <FormControlLabel control={<Switch defaultChecked={formData.status} onChange={handleStatus}/>} label="Habilitar" />
      </FormGroup>
      <Button onClick={handleCancel} variant="contained" sx={{marginRight:'10px'}}>
        Cancelar
      </Button>
      <Button onClick={handleSubmitFormData} color="primary" variant="contained" autoFocus>
        Aceptar
      </Button>
  </Fragment>;
};
