import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';
import { Customer } from './useGetCustomers';

const SERVICE_ID = 'getCustomerInfo';

export interface QuestionnaireAnswer {
  label: string;
  title: string;
  question_id: string;
  next_question_id: string;
  answer: {
    raw: AnyObject;
    logicalValue: AnyObject;
  };
}

export interface CustomerInfo {
  customer: Customer;
  answers: QuestionnaireAnswer[][];
}

export const useGetCustomerInfo = (params: AnyObject, options: UseStubbornServiceOptions) => {
  return useStubbornService<CustomerInfo>(SERVICE_ID, params, options);
};
