import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';
import { CisServiceField } from './useCreateCisServiceField';

const UPDATE_CIS_SERVICE_FIELD = 'updateCisServiceField';

export interface UpdateCisServiceFieldResponse {
  cis_service_field: CisServiceField;
}

export interface UpdateCisServiceFieldRequest {
  cis_service_id: string;
  field_service_to: string;
  field_type: string;
  data_type: string;
}

export const useUpdateCisServiceField = (params: UpdateCisServiceFieldRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<UpdateCisServiceFieldResponse>(UPDATE_CIS_SERVICE_FIELD, params, options);
};
