import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleTransaction } from '../../../../services';
import { Loading } from '../../../assets/Loading';
import { HeaderTable } from '../../../components/core/HeaderTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { statusTransaction, typeTransaction } from '../../../commons/hooks';

export const TransactionsDetails = ({ idx }: any) => {
  const { id } = useParams();
  const { callCustomers: callTransaction, data } = useGetSingleTransaction({ transactionId: '' }, { autoCall: false });
  const [transaction, setTransaction] = useState<null | any>(null);

  useEffect(() => {
    data && setTransaction(data.transaction);
  }, [data, setTransaction]);

  useEffect(() => {
    id && callTransaction({ transactionId: id });
  }, [id, callTransaction]);

  return !transaction ? (
    <Loading />
  ) : (
    <Grid container direction="column" p={5}>
      <Grid item>
        <HeaderTable title={`Transacción: ${data?.transaction.transactionId}`} />
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
            <Typography>Datos de transacción</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Tipo de transacción:</Typography>
                <Typography>{typeTransaction(transaction)}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Compañía:</Typography>
                <Typography>{data?.transaction.company.name}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Estado:</Typography>
                <Typography>{statusTransaction(transaction)}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Fecha:</Typography>
                <Typography>{moment(data?.transaction.updatedAt).format('D-MM-YYYY')}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
            <Typography>Datos de cliente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Email:</Typography>
                <Typography>{data?.transaction.customer.email}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>CIS Cliente ID:</Typography>
                <Typography>{data?.transaction.customer.cisId}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cliente ID:</Typography>
                <Typography>{data?.transaction.customer.customerId}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <div>
          {Object.entries(transaction.metadata).map((meta, idx) => {
            return (
              <Accordion sx={{ marginBottom: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
                  <Typography>{String(meta[0]).toUpperCase().split('_').join(' ')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>
                    <ReactJson src={{ ...meta }} collapsed={true} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};
/* display: flex;
  flex-wrap: wrap;
  justify-content: space-between */
