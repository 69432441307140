import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getUsers';



export interface BoUser {
  bo_user_id: string;
  account_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  first_name: string;
  last_name: string;
  email: string;
}

export interface BoUsers {
  bo_users: BoUser[];
}

export type PayloadAccounts = {
  filter?: AnyObject;
  orderBy?: string;
  direction?: string;
  initialLimit?: number;
  name?: string;
};

export const useGetAccounts = (params: PayloadAccounts, options: UseStubbornServiceOptions) => {
  return useStubbornService<BoUsers>(SERVICE_ID, params, options);
};
