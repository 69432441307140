import { Services } from '../../../../application/screens/Private/Services';
import { ServicesDatails } from '../../../../application/screens/Private/ServicesDatails';
import { UpdateServices } from '../../../../application/screens/Private/UpdateServices';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'services',
    basePath: '/',
    target: {
      component: Services,
      path: '/services',
    },
    enabled: true,
    icon: 'List',
    text: 'Services',
    children: [],
    default: true,
  },
  {
    key: 'cis-services-details',
    basePath: '/',
    target: {
      component: ServicesDatails,
      path: '/cis-services/:id',
    },
    enabled: true,
    icon: 'List',
    text: 'Services Datails',
    children: [],
    default: false,
  },
  {
    key: 'cis-services-edit',
    basePath: '/',
    target: {
      component: UpdateServices,
      path: '/cis-services/:id/edit',
    },
    enabled: true,
    icon: 'List',
    text: 'Services Edit',
    children: [],
    default: false,
  },
];
