// Palette
import palette from '../palette';

type MuiButtonType = { [k: string]: any };

const MuiButton: MuiButtonType = {
    outlined: {},
    contained: {
        backgroundColor: palette.common.white,
        '&:hover': {
            backgroundColor: palette.common.neutral,
        },
    },
};

export default MuiButton;
