import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    background: 'transparent',
    color: '#b5bcc9',
    fontSize: 13,
    fontWeight: 600,
    '& .MuiTableSortLabel-icon': {
      color: '#b5bcc9',
    },
    '& .MuiTableSortLabel-root': {
      color: '#b5bcc9',
    },
  },
});
