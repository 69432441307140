import React from 'react';
import { Button } from '@mui/material';
import { AnyObject } from '../../commons/types';
import { buttonStyles } from '../../../theme/styles';

interface ButtonCISProps {
  textButton: string;
  disabled?: boolean;
  size?: string;
  handleOnClick?: () => void;
  buttonDelete?: boolean;
  typeButton?: 'submit' | 'reset' | 'button' | undefined;
  customStyle?: AnyObject;
}

export const ButtonCIS = ({ textButton, handleOnClick, typeButton = 'submit', disabled = false, buttonDelete}: ButtonCISProps) => {
  const classes = buttonStyles();

  return (
    <Button className={buttonDelete ? classes.buttonDelete : classes.button} type={typeButton} disabled={disabled} onClick={handleOnClick}>
      {textButton}
    </Button>
  );
};
