import { Box, Grid, Modal, Snackbar, TextField, Typography } from '@mui/material';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useGetCisFormTemplatesService, useCreateCisService } from '../../../../../services';
import { ButtonCIS } from '../../../../components/button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SelectForm } from '../../../../components/Select';
import { ButtonGroupCIS } from '../../../../components/button-group';
import { ServiceCreateTable } from './ServiceCreateTable';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ServicesCreateForm = () => {
  const { callCustomers: callCreateCisService, error } = useCreateCisService({});
  const { data: dataTemplates } = useGetCisFormTemplatesService({}, { autoCall: true });
  const [isOpen, setIsOpen] = useState(false);
  const [valueName, setValueName] = useState('');
  const [valueTemplate, setValueTemplate] = useState([]);

  const dataSelectedTemplate = useMemo(
    () =>
      dataTemplates?.form_templates.map((item) => ({
        value: item.form_template_id,
        label: item?.name,
      })),
    [dataTemplates],
  );

  const callSendingData = async () => {
    try {
      await callCreateCisService({
        name: valueName,
        fields: [
          {
            field_service_to: 'test',
            field_type: 'request',
            data_type: 'string',
            field_prop_from: 'test',
            form_template_id: 1,
            form_field_id: 1,
            field_id: 1,
          },
        ],
      });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleChangeTemplates = (e: any) => {
    const {
      target: { value },
    } = e;
    setValueTemplate(typeof value === 'string' ? value.split(',') : value);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={6}>
          <Typography width={'100px'} textAlign={'center'} variant="h6" sx={{ fontWeight: 'bold' }}>
            Nombre:
          </Typography>
          <TextField sx={{ width: 500 }} type={'string'} value={valueName} onChange={(e) => setValueName(e.target.value)}></TextField>
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <ServiceCreateTable />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} gap={2}>
          {error ? (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                No se guardo correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Guardado correctamente
              </Alert>
            </Snackbar>
          )}
          <ButtonCIS typeButton="submit" textButton="Guardar servicio" handleOnClick={callSendingData} />
        </Grid>
      </Grid>
    </>
  );
};
