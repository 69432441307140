import { CisService } from '.';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const GET_CIS_SERVICE_BY_ID = 'getCisServiceById';

export interface GetCisServiceByIdRequest {
  cis_service_id?: string;
}

export interface GetCisServiceByIdResponse {
  cis_service: CisService;
}

export const useGetCisServiceById = (params: GetCisServiceByIdRequest, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<GetCisServiceByIdResponse>(GET_CIS_SERVICE_BY_ID, params, options);
};
