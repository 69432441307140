import { MappingCreate } from '../../../../application/screens/Private/MappingCreate';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const mappingCreateFormMenu: MenuItem = {
  key: 'mappingCreateFormMenu',
  basePath: '/mappings/create',
  target: { path: '/', component: MappingCreate },
  enabled: true,
  icon: 'List',
  text: 'Mapping Create',
  children: routes,
  default: true,
};
