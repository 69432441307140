import { TransactionsObj } from '../types/transactionTypes';

export const statusTransaction = (item: TransactionsObj) => {
  if (item.status.name == 'approved') {
    return 'Aprobado';
  }
  if (item.status.name == 'rejected') {
    return 'Rechazado';
  }
  if (item.status.name == 'pending') {
    return 'Pendiente';
  }
};
