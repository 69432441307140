import { ServicesFields } from '../../../../application/screens/Private/ServicesField';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const ServicesFieldsMenu: MenuItem = {
  key: 'servicesFieldsMenu',
  basePath: '/cis-services-fields/create',
  target: { path: '/', component: ServicesFields },
  enabled: true,
  icon: 'List',
  text: 'Services Fields',
  children: routes,
  default: true,
};
