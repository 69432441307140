import { makeStyles } from '@mui/styles';
import { primary } from '../colors';

export const inputStyles = makeStyles(() => ({
  input: {
    display: 'flex',
    color: '#000000',
    textTransform: 'none',
    '& .Mui-disabled': {
      '-webkit-text-fill-color': '#000000 !important',
    },
    '&.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
    '&:after': {
      borderBottom: `2px solid ${primary.yellowCis}`,
    },
  },
}));
