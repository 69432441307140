import { Box, Grid } from '@mui/material';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { ServicesDatailsForm } from './components';
import { useParams } from 'react-router-dom';
import { useGetCisServiceById } from '../../../../services/useGetCisServiceById';
import { Loading } from '../../../assets/Loading';

export const ServicesDatails = () => {
  const { id } = useParams();
  const { data, working } = useGetCisServiceById({ cis_service_id: id }, { autoCall: true });

  if (working) return <Loading />;

  return (
    <Box width="100%" height="100%" paddingX={4}>
      <Grid container rowSpacing={4} spacing={4}>
        <Grid item xs={8}>
          <HeaderTable title="Detalle de servicio" />
        </Grid>
        <Grid item xs={12}>
          <ServicesDatailsForm cisService={data?.cis_service} />
        </Grid>
      </Grid>
    </Box>
  );
};
