import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const GET_CIS_FORM_TEMPLATES_ID = 'getCisFormTemplates';

export interface GetCisFormTemplatesResponse {
  form_templates: GetCisFormTemplates[];
}

export type GetCisFormTemplates = {
  form_template_id: string;
  name: string;
};

export const useGetCisFormTemplatesService = (params = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<GetCisFormTemplatesResponse>(GET_CIS_FORM_TEMPLATES_ID, params, options);
};
