import { MouseEventHandler, ReactNode } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import typography from '../../../../theme/typography';
import { grey, primary } from '../../../../theme/colors';
import { AnyObject } from '../../../commons/types';
import { v4 as uuidv4 } from 'uuid';

export enum BREAK_POINTS {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export type ModalBaseProps = {
  id?: string;
  open: boolean;
  onClose?: MouseEventHandler;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  customTheme?: AnyObject;
  maxWidth?: BREAK_POINTS;
};

const modalBaseStyles = makeStyles(() => ({
  title: {
    fontSize: 28,
    fontWeight: 'normal',
    color: grey.medium,
    fontFamily: typography.fontFamily,
  },
  dialog: {
    margin: 'auto',
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    '& .MuiDialogActions-root': {
      boxShadow: '0px -3px 3px rgba(0, 0, 0, 0.1);',
      height: 36,
      paddingRight: 30,
    },
    '& .MuiDialogContent-root': {
      paddingTop: '0',
      '& > .MuiFormGroup-root': {
        marginBottom: '16px',
        '& > .MuiFormControlLabel-root': {
          marginTop: '6px',
        },
      },
      '& > .MuiButtonBase-root': {
        backgroundColor: primary.yellowCis,
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: typography.fontFamily,
        fontSize: 15,
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: 4,
        '& .Mui-checked > > .MuiSwitch-thumb': {
          color: primary.yellowCis,
        },
        '&:disabled': {
          color: '#000000',
          background: grey.textSecondary,
        },
        '&:hover': {
          background: primary.yellowCis,
          color: '#000000',
        },
      },
      '& .MuiSwitch-root': {
        '& > .Mui-checked > .MuiSwitch-thumb': {
          color: primary.yellowCis,
        },
        '& > .MuiSwitch-track': {
          backgroundColor: primary.yellowCis,
        },
      },
    },
  },
}));

export const ModalBaseCIS = ({ open = false, onClose, title, content, actions, customTheme, maxWidth = BREAK_POINTS.SM, id = `modal_${uuidv4()}` }: ModalBaseProps) => {
  const classes = modalBaseStyles();
  const dialog = (
    <Dialog open={open} onClose={onClose} className={classes.dialog} fullWidth maxWidth={maxWidth} key={id}>
      {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
    </Dialog>
  );
  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
