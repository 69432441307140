export const columns = [
  {
    id: 'cis_service_id',
    label: 'ID',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'service_name',
    label: 'Nombre del servicio',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'field_service_to',
    label: 'Campo destino',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'field_type',
    label: 'Tipo de campo',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'data_type',
    label: 'Tipo de dato',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'form_template_id',
    label: 'FormTemplateID',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'field_id',
    label: 'FieldID',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'field_prop_from',
    label: 'Campo origen',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'form_field_id',
    label: 'FormFieldID',
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: 'operations',
    label: 'Operaciones',
    hideSortIcon: true,
    noSort: true,
  },
];
