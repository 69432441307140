import {MenuItem} from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
    {
        key: 'setting',
        basePath: '/',
        target: {
            path: '/setting',
        },
        enabled: true,
        icon: 'List',
        text: 'Setting',
        children: [],
        default: true,
    },
];
