import React, { useState, MouseEvent } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuList } from '../MenuList';
import typography from '../../../../theme/typography';
import { grey, primary } from '../../../../theme/colors';
import { AnyObject } from '../../../commons/types';

interface MenuListTypesProps {
  title: string;
  handleOnClick: (event: MouseEvent) => void;
}

interface ButtonTypesProps {
  title: React.ReactNode;
  disabled?: boolean;
  size?: string;
  handleOnClick?: (event: MouseEvent) => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  type?: string;
  typeButton?: 'submit' | 'reset' | 'button' | undefined;
  customStyle?: AnyObject;
  customStyleIcon?: AnyObject;
  ListMenu?: MenuListTypesProps[];
}

const sizeText: Record<string, any> = {
  small: 11,
  medium: 14,
  large: 15,
};

const paddingButton: Record<string, any> = {
  small: '3px 16px',
  medium: '7px 16px',
  large: '8px 16px',
};

const backgroundColor: Record<string, any> = {
  primary: primary.yellowCis,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};

const backgroundColorDisabled: Record<string, any> = {
  primary: grey.textSecondary,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};

const backgroundColorHover: Record<string, any> = {
  primary: primary.yellowCis,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};
const textColorButton: Record<string, any> = {
  primary: primary.black,
  secondary: primary.lightBlue,
  cancel: primary.white,
  success: primary.white,
  error: primary.white,
  info: grey.textPrimary,
};

const borderButton: Record<string, string> = {
  info: `1px solid ${grey.normal}`,
};

export const MainButton = ({
  title,
  size = 'large',
  iconLeft,
  iconRight,
  disabled = false,
  type = 'primary',
  handleOnClick,
  customStyle,
  customStyleIcon,
  ListMenu,
  typeButton = 'button',
}: ButtonTypesProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonStyles = makeStyles(() => ({
    button: {
      background: backgroundColor[type],
      display: 'flex',
      color: textColorButton[type],
      fontWeight: 'normal',
      fontFamily: typography.fontFamily,
      fontSize: sizeText[size],
      textTransform: 'none',
      padding: paddingButton[size],
      borderRadius: 4,
      border: borderButton[type],
      '&:disabled': {
        color: primary.white,
        background: backgroundColorDisabled[type],
      },
      '&:hover': {
        background: backgroundColorHover[type],
      },
      ...customStyle,
    },
    icon: {
      marginTop: 8,
      ...customStyleIcon,
    },
  }));

  const classes = buttonStyles();

  return (
    <>
      <Button
        type={typeButton}
        className={classes.button}
        startIcon={iconLeft && <div className={classes.icon}>{iconLeft}</div>}
        endIcon={iconRight && <div className={classes.icon}>{iconRight}</div>}
        disabled={disabled}
        onClick={ListMenu ? handleOpenMenu : handleOnClick}
      >
        {title}
      </Button>
      {ListMenu && <MenuList anchorEl={anchorEl} setAnchorEl={setAnchorEl} ListMenu={ListMenu} />}
    </>
  );
};
