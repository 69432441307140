import React, { useMemo, useState } from 'react';
import {
    MenuCollection,
    MenuDivider,
    MenuItem,
    Metadata,
} from '../profiles/types';
import { Context } from './RouterContext';
import { MenuRoutes } from './MenuRoutes';

const isMenuItem = (menu: MenuItem | MenuDivider): menu is MenuItem =>
    (menu as MenuDivider).divider === undefined;

export const CreateRouter = ({config, Component}: {config: MenuCollection, Component: any}) => {
    const [metadata, setMetadata] = useState<Metadata>({});
    const menuItems: MenuItem[] = useMemo(() => config.filter(isMenuItem), [config]);
    // eslint-disable-next-line react/display-name

    const value = useMemo(
        () => ({
            config: menuItems,
            metadata,
            setMetadata,
        }),
        [menuItems, metadata],
    );
    return (
        <Context.Provider value={value}>
            <Component>
                <MenuRoutes config={menuItems} />
            </Component>
        </Context.Provider>
    );
};
