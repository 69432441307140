import { makeStyles } from '@mui/styles';
import { grey } from '../colors';
import typography from '../typography';

export const ModalServicesStyles = makeStyles(() => ({
  title: {
    fontSize: 28,
    fontWeight: 'normal',
    color: grey.medium,
    fontFamily: typography.fontFamily,
  },
  dialog: {
    margin: 'auto',
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    '& .MuiDialogActions-root': {
      boxShadow: '0px -3px 3px rgba(0, 0, 0, 0.1);',
      height: 36,
      paddingRight: 36,
    },
    '& .MuiDialogContent-root': {
      padding: '8px 10px',
    },
  },
}));
