import { Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { forwardRef, Fragment, useMemo, useState } from 'react';
import { useGetCisFormTemplatesService, useGetCisServices, useGetCisServicesFieldService, useDeleteCisMappingService } from '../../../../../services';
import { useCreateCisMappingService } from '../../../../../services/useCreateCisMapping';
import { ButtonCIS } from '../../../../components/button';
import { SelectForm } from '../../../../components/Select';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const UpdateMappingForm = ({ defaultParams }: any) => {
  const { data: dataCisServices } = useGetCisServices({}, { autoCall: true });
  const { data: dataCisField, callCustomers: callCisField } = useGetCisServicesFieldService({});
  const { data: dataFormTemplates } = useGetCisFormTemplatesService({}, { autoCall: true });
  const { callCustomers: callCreateCisMapping, error } = useCreateCisMappingService({});
  const { callCustomers: callDeleteCisMapping, error: errorDelete } = useDeleteCisMappingService({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const dataSelectedService = useMemo(
    () =>
      dataCisServices?.services.map((item) => ({
        value: item.cis_service_id,
        label: item?.name,
      })),
    [dataCisServices],
  );

  const dataSelectedField = useMemo(
    () =>
      dataCisField?.fields.map((item) => ({
        value: item.cis_service_field_id,
        label: item?.field_service_to,
      })),
    [dataCisField],
  );

  const dataSelectedTemplate = useMemo(
    () =>
      dataFormTemplates?.form_templates.map((item) => ({
        value: item.form_template_id,
        label: item?.name,
      })),
    [dataFormTemplates],
  );
  const [valueService, setValueService] = useState(defaultParams.cis_service_id || '');
  const [valueField, setValueField] = useState(defaultParams.cis_service_field_id || '');
  const [valueTemplate, setValueTemplate] = useState(defaultParams.form_template_id || 0);
  const [valueFormFieldID, setValueFormFieldID] = useState(defaultParams.form_field_id || 0);
  const [valueFieldID, setValueFieldID] = useState(defaultParams.field_id || 0);
  const [valueFieldPropFrom, setValueFieldPropFrom] = useState(defaultParams.field_prop_from || '');

  const onChangeService = (e: any) => {
    setValueService(e.target.value);
    callCisField({ cis_service_id: e.target.value });
  };
  const onChangeField = (e: any) => {
    setValueField(e.target.value);
  };
  const onChangeTemplate = (e: any) => {
    setValueTemplate(+e.target.value);
  };
  const onChangeFieldPropFrom = (e: any) => {
    setValueFieldPropFrom(e.target.value);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const handleCloseDelete = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenDelete(false);
  };

  const callSendingData = async () => {
    try {
      await callCreateCisMapping({
        cis_service_field_id: valueField,
        form_template_id: valueTemplate,
        form_field_id: valueFormFieldID,
        field_id: valueFieldID,
        field_prop_from: valueFieldPropFrom,
      });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };
  const callDeleteMapping = async () => {
    try {
      callDeleteCisMapping({
        cis_service_field_id: valueField,
      });
      setIsOpenDelete(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  console.log('valueField', valueField);

  return (
    <Fragment>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Servicio:
          </Typography>
          <SelectForm value={valueService} options={dataSelectedService} handleChange={onChangeService} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Campo:
          </Typography>
          <SelectForm value={valueField} options={dataSelectedField} handleChange={onChangeField} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Template:
          </Typography>
          <SelectForm value={valueTemplate} options={dataSelectedTemplate} handleChange={onChangeTemplate} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            FormFieldID:
          </Typography>
          <TextField type={'number'} value={valueFormFieldID} onChange={(e) => setValueFormFieldID(+e.target.value)} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            FieldID:
          </Typography>
          <TextField type={'number'} value={valueFieldID} onChange={(e) => setValueFieldID(+e.target.value)} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <Typography width={'100px'} textAlign={'center'}>
            Campo Origen:
          </Typography>
          <TextField type={'string'} value={valueFieldPropFrom} onChange={onChangeFieldPropFrom} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} gap={2}>
          {error ? (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                No se guardo correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Guardado correctamente
              </Alert>
            </Snackbar>
          )}
          {errorDelete ? (
            <Snackbar open={isOpenDelete} autoHideDuration={6000} onClose={handleCloseDelete}>
              <Alert onClose={handleCloseDelete} severity="error" sx={{ width: '100%' }}>
                No se elimino correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar open={isOpenDelete} autoHideDuration={6000} onClose={handleCloseDelete}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Eliminado correctamente
              </Alert>
            </Snackbar>
          )}
          <ButtonCIS typeButton="submit" textButton="Guardar" handleOnClick={callSendingData} />
          <ButtonCIS typeButton="submit" textButton="Eliminar" handleOnClick={callDeleteMapping} buttonDelete={true} />
        </Grid>
      </Grid>
    </Fragment>
  );
};
