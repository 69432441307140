import { AnyObject } from '../application/commons/types';
import { TransactionsObj } from '../application/commons/types/transactionTypes';
import { UseStubbornServiceOptions, useStubbornService } from '../lib/StubbornService/react';

const SERVICE_ID = 'getTransactions';

export type PayloadTransactions = {
  filter?: AnyObject;
  orderBy?: string;
  direction?: string;
  limit?: number;
  name?: string;
};

export interface TransactionsResponse {
  transaction_count: string;
  transactions: TransactionsObj[];
}

export const useGetTransactionsService = (params: PayloadTransactions = {}, options?: UseStubbornServiceOptions) => useStubbornService<TransactionsResponse>(SERVICE_ID, params, options);
