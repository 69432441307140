import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { permission } from './platform/permission';
import { profiles } from './platform/profiles';
import { PhinxAppProvider } from './lib/phinxapp/profiles/PhinxApp';
import './index.css';
import { StubbornMenuContextProvider } from './application/context';
import { StubbornServerContextProvider } from './lib/StubbornService/react';
import { Configuration } from './lib/StubbornService/StubbornServer';
import theme from './theme';

const rootElement = document.getElementById('root') as HTMLElement;
// eslint-disable-next-line
const root = createRoot(rootElement!);

export const configuration: Configuration = {
  url: process.env.REACT_APP_API_URL as string,
};

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <PhinxAppProvider profiles={profiles} rules={permission} profile="public">
            <StubbornMenuContextProvider>
              <StubbornServerContextProvider configuration={configuration}>
                <CssBaseline />
                <App />
              </StubbornServerContextProvider>
            </StubbornMenuContextProvider>
          </PhinxAppProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

reportWebVitals();
