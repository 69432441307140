import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getCustomers';

export interface Customer {
  customer_id: string;
  account_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  customers_count: number;
  phone: any;
  email: string;
  country_name: string;
  country_iso_code: string;
  cis_id: number;
  cis_profile_id: number;
  cis_user_id: number;
  onboarding_profile_status: boolean;
  device_id: string;
}
export interface CustomerResponse {
  customers: Customer[];
  customers_count: {
    total: string;
  };
}

export type PayloadCustomers = {
  filter?: string;
  orderBy?: string;
  direction?: string;
  limit?: number;
  name?: string;
  offset?: number;
};

export const useGetCustomers = (params: PayloadCustomers, options: UseStubbornServiceOptions) => {
  return useStubbornService<CustomerResponse>(SERVICE_ID, params, options);
};
