import { Box, Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { ServicesFieldsForm } from './components';

export const ServicesFields = () => (
  <Box width="100%" height="100%" paddingX={4}>
    <Grid container rowSpacing={4} spacing={4}>
      <Grid item xs={8}>
        <HeaderTable title="Alta de campo" />
      </Grid>
      <Grid item xs={12}>
        <ServicesFieldsForm />
      </Grid>
    </Grid>
  </Box>
);
