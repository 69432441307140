import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCustomerInfo } from '../../../../services/useGetCustomerInfo';
import { Loading } from '../../../assets/Loading';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { ButtonCIS } from '../../../components/button';

export const CustomerDetails = () => {
  const { id } = useParams();
  const { data, working } = useGetCustomerInfo({ customer_id: id }, { autoCall: true });
  const navigate = useNavigate();

  return working ? (
    <Loading />
  ) : (
    <Grid container direction="column" p={5}>
      <Grid item>
        <HeaderTable title="Detalle de cliente" />
      </Grid>
      <Grid item xs={12} mb={4}>
        <ButtonCIS
          typeButton="button"
          textButton="Transacciones"
          handleOnClick={() => {
            navigate(`/transactions`, { state: { email: data?.customer.email } });
          }}
        />
      </Grid>
      <Accordion style={{ marginBottom: 10 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20 }}>Datos de cliente</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cliente ID:</Typography>
              <Typography>{data?.customer?.customer_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cuenta ID:</Typography>
              <Typography>{data?.customer?.account_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black', marginLeft: 10 }}>Creado:</Typography>
              <Typography>{moment(data?.customer?.created_at).format('D-MM-YYYY')}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Teléfono:</Typography>
              <Typography>{data?.customer?.phone}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Email:</Typography>
              <Typography>{data?.customer?.email}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Dispositivo ID:</Typography>
              <Typography>{data?.customer?.device_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>País:</Typography>
              <Typography>{data?.customer?.country_name}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Estado de onboarding:</Typography>
              <Typography>{String(data?.customer?.onboarding_profile_status)}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: 10 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20 }}>Cuenta de CIS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>CIS ID:</Typography>
              <Typography>{data?.customer?.cis_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Usuario ID:</Typography>
              <Typography>{data?.customer?.cis_user_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Perfil ID:</Typography>
              <Typography>{data?.customer?.cis_profile_id}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {data?.answers &&
        data?.answers[0] &&
        data?.answers[0]?.map((item: any) => {
          const { answer, title } = item;
          if (!answer || !answer.raw || Object.keys(answer?.raw || {}).length <= 0) {
            return null;
          }
          const keysForm = answer?.raw && Object.keys(answer.raw);

          return !keysForm?.length ? null : (
            <Accordion style={{ marginBottom: 10 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 10 }}>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" style={{ marginTop: 10 }}>
                  <Grid container direction="row">
                    {keysForm.map((x: any) => (
                      <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                        <Typography style={{ fontWeight: 'bold', color: 'black' }}>{x}</Typography>
                        <Typography>{(answer.raw && answer.raw[x]) || '-'}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Grid>
  );
};
