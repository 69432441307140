import { Collapse, Grid, Paper, styled, TextField } from "@mui/material"
import { KeyboardArrowLeft as MUIKeyboardArrowLeft } from "@mui/icons-material"

export const GridLayout = styled(Grid)({
  display: 'grid',
  gridTemplateRows: '100px auto',
  height: '100vh',
  width: '100vw',
  overflow: 'auto', 
  backgroundColor: '#F8F8F8'
})

export const GridMenu = styled(Grid)({
  height: '100%',
  backgroundColor: '#F8F8F8',
  gridTemplateColumns: '200px auto',
  overflow: 'auto',
  display: 'grid',
})

export const GridHeader = styled(Grid)({
  backgroundColor: 'transparent',
  display: 'grid',
  gridTemplateColumns: '200px auto 2%'
})

export const GridMenuIcon = styled(Grid)({
  display: 'flex',
  width: '200px',
  padding: '30px'
})

export const WhiteGrid = styled(Paper)({
  backgroundColor: '#ffffff',
  height: '100%'
})

export const GridMenuOptions = styled(Grid)({
  display: 'grid',
  gridTemplateRows: '40px repeat(5, 50px)'
})

export const GridCollapseMenuOptions = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end'
})

export const KeyboardArrowLeft = styled(MUIKeyboardArrowLeft)({
  color: '#74A298'
})

export const CustomCollapse= styled(Collapse)({
  height: '100%',
  '& .MuiCollapse-wrapper': {
    height: '100%',
  },
  '& .MuiCollapse-wrapperInner': {
    height: '100%'
  },
})

export const SearchTextField = styled(TextField)({
  borderRadius: '30px',
  borderRight: '',
  borderLeft: '',
  borderBottom: '',
  boxShadow: '10px 5px 11px -10px rgba(0,0,0,0.75)',
  '&.MuiInputBase-root':{
    borderRadius: '30px',
  },
})

export const TextFieldContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 15,
  paddingRight: 15,
})
