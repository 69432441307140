import palette from '../palette';

type MuiIconButtonType = { [k: string]: any };

const MuiIconButton: MuiIconButtonType = {
    root: {
        padding: '10px',
        color: palette.text.secondary,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
    },
};

export default MuiIconButton;
