import { Grid, Box } from '@mui/material';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { Loading } from '../../../assets/Loading';
import { useGetEventLogs } from '../../../../services/useGetEventLogs';
import { EventLogsTable } from './components/EventLogsTable';

export const EventLogs = () => {
  const { working, data } = useGetEventLogs({}, { autoCall: true });

  if (working) return <Loading />;

  return (
    <Box width="100%" height="100%" paddingX={4}>
      <Grid container rowSpacing={4} spacing={4}>
        <Grid item xs={8}>
          <HeaderTable title="Eventos" />
        </Grid>
        <Grid item xs={12}>
          <EventLogsTable events={data?.events} />
        </Grid>
      </Grid>
    </Box>
  );
};
