import { Box, Grid, LinearProgress, styled, Typography } from '@mui/material';
import { CustomersStats } from '../../../../services/useGetDashboardStats';
import pallete from '../../../../theme/palette';
import { APP } from '../../../constants';
import { LineChart } from './LineChart';

const OverviewAndAnalyticsContainer = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '70% 30%',
  marginRight: '20px',
  marginLeft: '20px',
  height: 'auto',
  '@media (max-width: 900px)': {
    display: 'block',
    height: '73%',
  },
});

const AnalitycsReportContainer = styled(Grid)({
  display: 'grid',
  gridTemplateRows: '25% 75%',
  height: '100%',
  width: '100%',
});

const AnalitycsFiltersContainer = styled(Grid)({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  minWidth: '173px',
});

const AnalitycsChartContainer = styled(Grid)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignSelf: 'flex-end',
});

const AnalyticsReportCard = styled(Grid)({
  width: '96%',
  borderRadius: 15,
  backgroundColor: pallete.background.paper,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  padding: '20px 0',
});

const IdentityTransitionCardContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 0.45,
});

const IdentityTransitionCard = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  minWidth: 250,
  borderRadius: 15,
  padding: 15,
});

const OrangeLinearProgress = styled(LinearProgress)({
  width: '100%',
  height: 10,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#ee744c',
  },
});

const GreenLinearProgress = styled(LinearProgress)({
  width: '100%',
  height: 10,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#57E464',
  },
});

const BlueLinearProgress = styled(LinearProgress)({
  width: '100%',
  height: 10,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#0F93F8',
  },
});

const PurpleLinearProgress = styled(LinearProgress)({
  width: '100%',
  height: 10,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#676EDD',
  },
});

const EmeraldLinearProgress = styled(LinearProgress)({
  width: '100%',
  height: 10,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#36D1BF',
  },
});

export interface OverviewAnalyticsProps {
  customers_stats?: CustomersStats | null;
}

export const OverviewAnalytics = ({ customers_stats }: OverviewAnalyticsProps) => {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  return (
    <OverviewAndAnalyticsContainer>
      <AnalitycsReportContainer>
        <AnalitycsFiltersContainer></AnalitycsFiltersContainer>
        <AnalitycsChartContainer>
          <Typography style={{ marginBottom: 15, fontWeight: 'bold', color: 'black', fontSize: 22 }}>
            <strong>Clientes registrados</strong>
          </Typography>
          <AnalyticsReportCard>
            <LineChart
              data={{
                datasets: [
                  {
                    label: 'Clientes',
                    data: labels.map((x, i) => customers_stats?.by_month[i] || 0),
                  },
                ],
                labels: APP.MONTHS,
              }}
            />
          </AnalyticsReportCard>
        </AnalitycsChartContainer>
      </AnalitycsReportContainer>
      <IdentityTransitionCardContainer>
        <Typography style={{ marginBottom: 15, fontWeight: 'bold', color: 'black', fontSize: 22 }}>
          <strong>Clientes por país</strong>
        </Typography>
        <IdentityTransitionCard>
          <Box display="flex" flex={1} alignItems="center">
            <Box display="flex" justifyContent="space-between" height="80%" flex={1} width="100%" flexDirection="column">
              {customers_stats?.by_country
                .filter((x) => x.country_id !== null)
                .map((x) => (
                  <Box display="flex" flex={1} width="100%" flexDirection="column">
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography variant="caption">{x.country_name}</Typography>
                      <Typography variant="caption">{x.total}</Typography>
                    </Box>
                    <OrangeLinearProgress variant="determinate" value={(x.total / customers_stats.total) * 100} />
                  </Box>
                ))}
            </Box>
          </Box>
        </IdentityTransitionCard>
      </IdentityTransitionCardContainer>
    </OverviewAndAnalyticsContainer>
  );
};
