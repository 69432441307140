export const columns = [
  {
    id: 'name',
    label: 'Nombre del servicio',
    hideSortIcon: false,
    noSort: false,
  },
  {
    id: 'fields',
    label: 'Campos',
    hideSortIcon: false,
    noSort: false,
  },
  {
    id: 'templates',
    label: 'Templates',
    hideSortIcon: false,
    noSort: false,
  },
  {
    id: 'mappings',
    label: 'Mapeos',
    hideSortIcon: false,
    noSort: false,
  },
  {
    id: 'operations',
    label: 'Operaciones',
    hideSortIcon: true,
    noSort: true,
  },
];
