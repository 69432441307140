import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../assets/Loading';
import { useGetCustomerQuestionnaireResume } from '../../../../services/useGetCustomerQuestionnaireResume';

export const CustomerQuestionnaireResume = () => {
  const { customer_id, questionnaire_id } = useParams();
  const { data, working } = useGetCustomerQuestionnaireResume({ customer_id, questionnaire_id }, { autoCall: false });

  return working ? (
    <Loading />
  ) : (
    <Grid container direction="column" p={5}>
      <Grid item xs={12}>
        <Typography gutterBottom>Total de preguntas: {data?.questionnaire?.total_questions || 0}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>Siguiente Pregunta: {data?.questionnaire?.next_question?.title || '-'}</Typography>
        <Typography gutterBottom>Respuestas: {data?.questionnaire?.answers.length || 0}</Typography>
      </Grid>
    </Grid>
  );
};
