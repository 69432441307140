import React, { useContext } from 'react';
import { RouterContext } from './types';

export const Context = React.createContext<RouterContext>({
    config: [],
    metadata: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMetadata: () => {},
});

export const useRouterContext = () => useContext<RouterContext>(Context);
