import { Box, Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { MappingCreateForm } from './components';

export const MappingCreate: FunctionComponent<any> = () => {
  return (
    <Box width="100%" height="100%" paddingX={4}>
      <Grid container rowSpacing={4} spacing={4}>
        <Grid item xs={8}>
          <HeaderTable title="Alta de mapeo" />
        </Grid>
        <Grid item xs={12}>
          <MappingCreateForm />
        </Grid>
      </Grid>
    </Box>
  );
};
