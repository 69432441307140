import React, { useCallback, useState } from 'react';

export type DirectionOption = 'asc' | 'desc';

enum Direction {
  Asc = 'asc',
  Desc = 'desc'
}

export const useOrderBy = (initialOrderByValue = '', initialDirectionValue: DirectionOption = 'asc') => {
  const [direction, setDirection] = React.useState<DirectionOption>(initialDirectionValue);
  const [orderBy, setOrderBy] = useState(initialOrderByValue);
  const onOrderByDirectionChange = useCallback((newOrderBy: string, newDirection: DirectionOption) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  }, []);

  return {
    direction,
    setDirection,
    orderBy,
    setOrderBy,
    onOrderByDirectionChange,
  };
};
