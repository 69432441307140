import { MappingCreate } from '../../../../application/screens/Private/MappingCreate';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'mapping-create',
    basePath: '/',
    target: {
      component: MappingCreate,
      path: '/mappings/create',
    },
    enabled: true,
    icon: 'List',
    text: 'MappingCreate',
    children: [],
    default: true,
  },

];
