//@ts-nocheck
import { Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { forwardRef, Fragment, useMemo, useState } from 'react';
import { useGetCisFormTemplatesService, useGetCisServices, useGetCisServicesFieldService } from '../../../../../services';
import { useCreateCisMappingService } from '../../../../../services/useCreateCisMapping';
import { ButtonCIS } from '../../../../components/button';
import { SelectForm } from '../../../../components/Select';
import { useForm } from 'react-hook-form';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MappingCreateForm = () => {
  const { data: dataCisServices } = useGetCisServices({}, { autoCall: true });
  const { data: dataCisField, callCustomers: callCisField } = useGetCisServicesFieldService({});
  const { data: dataFormTemplates } = useGetCisFormTemplatesService({}, { autoCall: true });
  const { callCustomers: callCreateCisMapping, error } = useCreateCisMappingService({});
  const { handleSubmit } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const dataSelectedService = useMemo(
    () =>
      dataCisServices?.services.map((item) => ({
        value: item.cis_service_id,
        label: item?.name,
      })),
    [dataCisServices],
  );

  const dataSelectedField = useMemo(
    () =>
      dataCisField?.fields.map((item) => ({
        value: item.cis_service_field_id,
        label: item?.field_service_to,
      })),
    [dataCisField],
  );

  const dataSelectedTemplate = useMemo(
    () =>
      dataFormTemplates?.form_templates.map((item) => ({
        value: item.form_template_id,
        label: item?.name,
      })),
    [dataFormTemplates],
  );
  const [valueService, setValueService] = useState('');
  const [valueField, setValueField] = useState('');
  const [valueTemplate, setValueTemplate] = useState(0);
  const [valueFormFieldID, setValueFormFieldID] = useState(0);
  const [valueFieldID, setValueFieldID] = useState(0);
  const [valueFieldPropFrom, setValueFieldPropFrom] = useState('');

  const onChangeService = (e: any) => {
    setValueService(e.target.value);
    callCisField({ cis_service_id: e.target.value });
  };
  const onChangeField = (e: any) => {
    setValueField(e.target.value);
  };
  const onChangeTemplate = (e: any) => {
    setValueTemplate(+e.target.value);
  };
  const onChangeFieldPropFrom = (e: any) => {
    setValueFieldPropFrom(e.target.value);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const callSendingData = async () => {
    try {
      await callCreateCisMapping({
        cis_service_field_id: valueField,
        form_template_id: valueTemplate,
        form_field_id: valueFormFieldID,
        field_id: valueFieldID,
        field_prop_from: valueFieldPropFrom,
      });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Servicio:
          </Typography>
          <SelectForm multiple={false} name={'service'} value={valueService} options={dataSelectedService} handleChange={onChangeService} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Campo:
          </Typography>
          <SelectForm multiple={false} name={'field'} value={valueField} options={dataSelectedField} handleChange={onChangeField} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Template:
          </Typography>
          <SelectForm multiple={false} name={'template'} value={valueTemplate} options={dataSelectedTemplate} handleChange={onChangeTemplate} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            FormFieldID:
          </Typography>
          <TextField name={'formFieldID'} type={'number'} value={valueFormFieldID} onChange={(e) => setValueFormFieldID(+e.target.value)} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            FieldID:
          </Typography>
          <TextField name={'fieldID'} type={'number'} value={valueFieldID} onChange={(e) => setValueFieldID(+e.target.value)} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <Typography width={'100px'} textAlign={'center'}>
            Campo Origen:
          </Typography>
          <TextField name={'fieldPropFrom'} type={'string'} value={valueFieldPropFrom} onChange={onChangeFieldPropFrom} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          {error && (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                No se guardo correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          )}
          <ButtonCIS typeButton="submit" textButton="Guardar" handleOnClick={handleSubmit(callSendingData)} />
        </Grid>
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Guardado correctamente
          </Alert>
        </Snackbar>
      </Grid>
    </Fragment>
  );
};
