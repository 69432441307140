import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ListItem, Typography, Collapse } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import palette from '../../../../../../../theme/palette';
import { Option } from './../../MenuStyles';
type ItemMenuDashboardProps = {
  iconContainer: JSX.Element;
  label: string;
  subMenu: { path: string; title: string; icon: JSX.Element }[];
};
export const ItemMenuDashboard = ({ iconContainer, label, subMenu }: ItemMenuDashboardProps) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Fragment>
      <ListItem disableGutters onClick={handleClick}>
        <Option>
          {iconContainer}
          <Typography color={palette.text.green} textTransform="capitalize" textAlign="left" style={{ textDecoration: 'none', cursor: 'pointer' }}>
            {label}
          </Typography>
        </Option>
        {open ? <ExpandMore style={{ color: palette.text.green }} /> : <ExpandLess style={{ color: palette.text.green }} />}
      </ListItem>
      <Collapse in={!open} timeout="auto">
        {subMenu.map(({ path, title, icon }) => (
          <ListItem disableGutters sx={{ pl: 2 }}>
            <NavLink to={path} color={palette.text.green} style={{ textDecoration: 'none' }}>
              <Option>
                {icon}
                <Typography color={palette.text.green} textTransform="capitalize" textAlign="left" style={{ textDecoration: 'none' }}>
                  {title}
                </Typography>
              </Option>
            </NavLink>
          </ListItem>
        ))}
      </Collapse>
    </Fragment>
  );
};
