import React, { useMemo, useState } from 'react';
import { FormContext, TemplateContext, TemplateContextValue } from './context';
import { onSubmitOption, useTemplateForm } from '../hooks';
import { Template } from '../types';

export const FormContextProvider = ({
  children,
  template: _template,
  initialValues,
  onSubmit,
}: {
  children: JSX.Element | JSX.Element[];
  template: Template;
  initialValues: any;
  onSubmit: onSubmitOption;
}) => {
  const [template, setTemplate] = useState<Template>(_template);
  const templateValue = useMemo<TemplateContextValue>(() => ({ template, setTemplate }), [template]);
  const form = useTemplateForm({ initialValues, onSubmit, template });

  return (
    <TemplateContext.Provider value={templateValue}>
      <FormContext.Provider value={form}>{children}</FormContext.Provider>
    </TemplateContext.Provider>
  );
};
