export enum SIDEMENU_ID {
  // Side Menu
  DASHBOARD = 1,
  USERS = 2,
  CUSTOMERS = 3,
  TRANSACTIONS = 4,
  SETTING = 5,
  EVENTS = 6,
}

export enum SUBMENU_ID {
  // Sub Menu
  SERVICE = 1,
}
