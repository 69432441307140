export const LOGIN_TEMPLATE = {
  name: 'Login template',
  template: [
    {
      name: 'email',
      type: { name: 'string' },
      label: 'Email',
      validators: [
        { name: 'email', signature: [{ tlds: { allow: false } }], errorMessage: 'Enter a valid email' },
        { name: 'empty', errorMessage: 'Complete this field.' },
      ],
    },
    {
      name: 'password',
      type: { name: 'string' },
      label: 'Password',
      validators: [
        { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'], errorMessage: '' },
        { name: 'empty', errorMessage: 'Complete this field.' },
      ],
    },
  ],
};
