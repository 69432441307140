import { useMemo } from 'react';
import { usePermissionContext } from '../permissionContext';

type SubMenu = {
  path: string;
  icon: JSX.Element;
  title: string;
  id: number;
};

export const usePermissionSideFilter = (
  sideMenu: {
    id: number;
    title: string;
    icon: JSX.Element;
    path: string;
    dataSubMenu?: SubMenu[];
  }[],
) => {
  const { activeRules } = usePermissionContext();
  return useMemo(() => sideMenu.filter((side) => !activeRules.sideMenu.find((ruleSide) => ruleSide.id === side.id)), [activeRules, sideMenu]);
};
