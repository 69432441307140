import { Login } from '../../../application/screens/Public';
import {MenuCollection} from '../../../lib/phinxapp/profiles/types';

export const publicMenu: MenuCollection = [
    {
        key: 'public',
        basePath: '/',
        enabled: true,
        icon: 'List',
        text: 'Public',
        default: true,
        target: {
            component: Login,
            path: '/',
        },
        children: [],
    },
];
