import { useMemo, useState, useCallback, useEffect, Fragment } from 'react';
import { Grid, Box, ThemeProvider } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { Customer, useGetCustomers } from '../../../../services';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { InfoTable } from '../../../components/core/InfoTable';
import { useDebounce, useOrderBy } from '../../../commons/hooks';
import { exportToCsv } from '../../../utils/exportToCsv';
import { columns } from './utils/column';
import { TableFilter } from '../../../components/core/TableFilter';
import { useSnackbar } from 'notistack';
import { ButtonGroupCIS } from '../../../components/button-group';
import { InfoTableNavbar } from '../../../components/core';
import { Loading } from '../../../assets/Loading';
import { buttonStyles } from '../../../../theme/styles';
import { DataGrid, GridRowId, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';
import { GridColDef } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import ReceiptIcon from '@mui/icons-material/Receipt';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

export const Customers = () => {
  const { data, working: loading, callCustomers } = useGetCustomers({}, { autoCall: false });
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

  // const optionsComponent = useCallback(
  //   (customer: Customer) => {
  //     return (
  //       <ButtonGroupCIS
  //         options={[
  //           {
  //             label: 'Seleccione una opción',
  //             disabled: true,
  //             handle: () => {
  //               return null;
  //             },
  //           },
  //           {
  //             label: 'Detalle',
  //             disabled: false,
  //             handle: () => {
  //               navigate(`/customers/${customer.customer_id}`);
  //             },
  //           },
  //           {
  //             label: 'Transacciones',
  //             disabled: false,
  //             handle: () => {
  //               navigate(`/transactions`, { state: { email: customer.email } });
  //             },
  //           },
  //         ]}
  //       ></ButtonGroupCIS>
  //     );
  //   },
  //   [navigate],
  // );

  const customers = useMemo(
    () =>
      data?.customers.map((item, idx) => ({
        customer_id: item?.customer_id,
        email: item?.email,
        country_name: item?.country_name,
        created_at: moment(item.created_at).format('D-MM-YYYY, HH:mm:ss'),
        cis_id: item?.cis_id,
        cis_profile_id: item?.cis_profile_id,
        cis_user_id: item?.cis_user_id,
        key: `element_${idx}`,
      })) || [],
    [data],
  );

  const columnsCustomer: GridColDef[] = [
    { field: 'customer_id', headerName: 'ID', flex: 1, editable: false },
    { field: 'email', headerName: 'Email', flex: 1, editable: false },
    { field: 'country_name', headerName: 'País', flex: 1, editable: false },
    { field: 'created_at', headerName: 'Fecha de creación', flex: 1, editable: false },
    { field: 'cis_id', headerName: 'CIS ID', flex: 1, editable: false },
    { field: 'cis_profile_id', headerName: 'Usuario ID', flex: 1, editable: false },
    { field: 'cis_user_id', headerName: 'Perfil ID', flex: 1, editable: false },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />,
          // <GridActionsCellItem icon={<ReceiptIcon />} label="Details" className="textPrimary" onClick={handleCustomersClick(id)} color="inherit" />,
        ];
      },
    },
  ];

  const handleDatailsClick = (id: GridRowId) => () => {
    navigate(`/customers/${id}`);
  };

  // const handleCustomersClick = (customer: Customer) => () => {
  //   navigate(`/transactions`, { state: { email: customer.email } });
  // };

  useEffect(() => {
    callCustomers({ limit: paginationModel.pageSize, offset: paginationModel.page, orderBy: '', direction: '' });
  }, [paginationModel]);

  console.log(data);
  return (
    // <Box width="100%" height="100%" paddingX={4}>
    //   <Grid container rowSpacing={4} spacing={4}>
    //     <Grid item xs={8}>
    //       <HeaderTable title="Clientes" titleMainButton="Exportar Csv" handleOnClick={toExportCsv} />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TableFilter search={value} onSearchChange={setValue} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <InfoTable
    //         customStyle={{ height: '35rem', marginTop: 5 }}
    //         rowIdKey="customer_id"
    //         columns={columns}
    //         rows={customers}
    //         key="customer_id"
    //         orderBy={orderBy}
    //         direction={direction}
    //         working={working || loading}
    //         onSortChange={onOrderByDirectionChange}
    //         showCount={true}
    //       />
    //     </Grid>
    //     <Grid item xs={12} mt={4} mb={4} display={'flex'} justifyContent={'center'}>
    //       {!loading ? (
    //         <InfoTableNavbar
    //           listData={totalPages}
    //           listController={openList}
    //           openList={open}
    //           onBackClick={onBackClick}
    //           onNextClick={onNextClick}
    //           nextDisabled={loading || endReached}
    //           backDisabled={loading}
    //           currentPage={currentPage}
    //           pageUpdate={(newPage: number) => pagesListNavigate(newPage)}
    //         />
    //       ) : (
    //         <Loading viewBox="0 0 100 100" width="30px" height="30px" stroke="#FAC306" />
    //       )}
    //     </Grid>
    //   </Grid>
    // </Box>
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Clientes" />
          </Grid>
          <Grid item pb={4} sx={{ height: 700, width: '100%', '& .actions': { color: 'text.secondary' }, '& .textPrimary': { color: 'text.primary' } }}>
            <ThemeProvider theme={themeDataGrid}>
              <DataGrid
                localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                getRowId={(id) => id?.customer_id}
                columns={columnsCustomer}
                rows={customers || []}
                slots={{ toolbar: CustomToolbar }}
                loading={loading}
                rowCount={+(data?.customers_count.total || 0)}
                pageSizeOptions={[10]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
