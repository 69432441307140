import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const CREATE_CIS_SERVICE_FIELD_ID = 'createCisServiceField';

export type CisServiceField = {
  cis_service_id: string;
  field_service_to: string;
  field_type: string;
  data_type: string;
};

export interface CreateCisServiceFieldResponse {
  cis_service_field: CisServiceField[];
}

export interface CreateCisServiceFieldRequest {
  cis_service_id: string;
  field_service_to: string;
  field_type: string;
  data_type: string;
}

export const useCreateCisServiceField = (params: CreateCisServiceFieldRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<CreateCisServiceFieldResponse>(CREATE_CIS_SERVICE_FIELD_ID, params, options);
};
