// Palette
import palette from '../palette';

type MuiTableRowType = { [k: string]: any };

const MuiTableRow: MuiTableRowType = {
    root: {
        height: '56px',
        '&$selected': {
            backgroundColor: palette.background.default,
        },
        '&$hover': {
            '&:hover': {
                backgroundColor: palette.background.default,
            },
        },
    },
};

export default MuiTableRow;
