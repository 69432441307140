import { SVGProps } from "react"

const HomeIco = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.7} height={19.5} {...props}>
    <path
      d="M0 6.3 8.1 0l8.1 6.3v9.9a1.8 1.8 0 0 1-1.8 1.8H1.8A1.8 1.8 0 0 1 0 16.2z"
      style={{
        fill: "none",
        stroke: "#909090",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeWidth: "1.5px",
      }}
      transform="translate(.75 .75)"
    />
    <path
      data-name="Path"
      d="M0 9V0h5.4v9"
      transform="translate(6.15 9.75)"
      style={{
        fill: "none",
        stroke: "#909090",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeWidth: "1.5px",
      }}
    />
  </svg>
)

export default HomeIco
