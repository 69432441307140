import { Users } from '../../../../application/screens/Private';
import { Dashboard } from '../../../../application/screens/Private/Dashboard';
import {MenuItem} from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
    {
        key: 'dashboard',
        basePath: '/',
        target: {
            component: Dashboard,
            path: '/dashboard',
        },
        enabled: true,
        icon: 'List',
        text: 'Dashboard',
        children: [],
        default: true,
    },
];
