import { Grid, Typography } from '@mui/material';
import { DashboardStats } from '../../../../services/useGetDashboardStats';
import { grey, primary } from '../../../../theme/colors';

export interface OverviewStatisticsProps {
  stats?: DashboardStats | null;
}

export const OverviewStatistics = ({ stats }: OverviewStatisticsProps) => {
  return (
    <Grid container direction={'row'}>
      <Grid item xs={12} ml={2}>
        <Typography gutterBottom variant="h4" style={{ marginBottom: 15, fontWeight: 'bold', color: 'black' }}>
          <strong>Estadísticas generales</strong>
        </Typography>
      </Grid>
      <Grid item alignItems="center" justifyContent="center" alignContent="center" xs={3} p={2} m={2} style={{ color: primary.white, background: grey.shade, borderRadius: 20, textAlign: 'center' }}>
        <Typography gutterBottom variant="h5">
          {`Total de clientes`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.customers?.total}</strong>
        </Typography>
      </Grid>
      <Grid item alignItems="center" justifyContent="center" alignContent="center" xs={3} p={2} m={2} style={{ color: primary.white, background: grey.shade, borderRadius: 20, textAlign: 'center' }}>
        <Typography gutterBottom variant="h5">
          {`Clientes registrados últ. semana`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.customers?.last_week}</strong>
        </Typography>
      </Grid>
      <Grid item alignItems="center" justifyContent="center" alignContent="center" xs={3} p={2} m={2} style={{ color: primary.white, background: grey.shade, borderRadius: 20, textAlign: 'center' }}>
        <Typography gutterBottom variant="h5">
          {`Clientes registrados últ. mes`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.customers?.last_month}</strong>
        </Typography>
      </Grid>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        xs={3}
        p={2}
        m={2}
        style={{ color: primary.black, background: primary.yellowCis, borderRadius: 20, textAlign: 'center' }}
      >
        <Typography gutterBottom variant="h5">
          {`Total transacciones`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.transactions?.total}</strong>
        </Typography>
      </Grid>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        xs={3}
        p={2}
        m={2}
        style={{ color: primary.black, background: primary.yellowCis, borderRadius: 20, textAlign: 'center' }}
      >
        <Typography gutterBottom variant="h5">
          {`Transacciones últ. semana`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.transactions?.last_week}</strong>
        </Typography>
      </Grid>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        xs={3}
        p={2}
        m={2}
        style={{ color: primary.black, background: primary.yellowCis, borderRadius: 20, textAlign: 'center' }}
      >
        <Typography gutterBottom variant="h5">
          {`Transacciones últ. mes`}
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>{stats?.transactions?.last_month}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};
