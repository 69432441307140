import { routes } from './routes';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { EventLogs } from '../../../../application/screens/Private/EventLogs';

export const eventsMenu: MenuItem = {
  key: 'eventsMenu',
  basePath: '/events',
  target: { path: '/', component: EventLogs },
  enabled: true,
  icon: 'List',
  text: 'events',
  children: routes,
  default: true,
};
