import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const CREATE_CIS_MAPPING_ID = 'createCisMapping';

export interface CreateCisMappingResponse {
  cis_map: createCisMapping[];
}

export type createCisMapping = {
  cis_service_field_id: string;
  cis_service_id: string;
  field_service_to: string;
  data_type: string;
  field_type: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
};

export interface CreateCisMappingRequest {
  cis_service_field_id: string;
  form_template_id: number;
  form_field_id: number;
  field_id: number;
  field_prop_from: string;
}

export const useCreateCisMappingService = (params: CreateCisMappingRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<CreateCisMappingResponse>(CREATE_CIS_MAPPING_ID, params, options);
};
