import { useState } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { useSnackbar } from 'notistack';
import { useProfileContext } from '../../../../lib/phinxapp/profiles/ProfileContext';
import { useStubbornAuth } from '../../../../lib/StubbornService/react';
import { FormContextProvider } from '../../../components/templates';
import { LoginForm } from './components/LoginForm';
import { LOGIN_TEMPLATE } from './template';

export interface LoginValues {
  email: string;
  password: string;
}

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const { setProfile } = useProfileContext();
  const SBAuth = useStubbornAuth();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async ({ email, password }: LoginValues) => {
    try {
      setLoading(true);
      await new Promise((r) => setTimeout(r, 2000));
      const res = await SBAuth.auth({ email, password });
      if (!res) {
        throw new Error('Error');
      }
      setProfile('private');
    } catch (err) {
      enqueueSnackbar(`Usuario o contraseña incorrecto.`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid>
        <FormContextProvider initialValues={{ email: '', password: '' }} onSubmit={onSubmit} template={LOGIN_TEMPLATE}>
          <Grid container>
            <Grid md={6} mdOffset={3}>
              <LoginForm loading={loading} />
            </Grid>
          </Grid>
        </FormContextProvider>
      </Grid>
    </Grid>
  );
};
