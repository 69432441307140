import React, { FC, Fragment } from 'react';
import cx from 'classnames';
import { Typography, TableCell, TableBody, Paper, Table, TableContainer, Grid, styled, TableRow as TableRowMUI } from '@mui/material';
import { AnyObject, InfoTableProps } from '../../../commons/types';
import _ from 'lodash';
import { EnhancedTableHead } from './components/EnhancedTableHead';
import { Skeleton } from '@mui/lab';
import { InfoTableStyles } from '../../../../theme/styles';

export const TableRowStyles = styled(TableRowMUI)({
  '&.MuiTableRow-hover:hover': {
    backgroundColor: '#EAEFEE',
  },
});

const CustomPaper = styled(Paper)({});

const InfoTableRaw: FC<InfoTableProps> = ({
  columns,
  rows,
  onBottomScroll,
  onRowClick,
  rowIdKey = 'id',
  onSortChange,
  orderBy,
  direction: order = 'asc',
  working,
  customStyle,
  customClassnameTypography,
  showCount,
  hover = true,
  elevation = true,
  countTitle = 'Registros cargados',
  hideHeader = false,
}: InfoTableProps) => {
  let preventMultipleCall = false;
  const classes = InfoTableStyles();

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom && onBottomScroll && preventMultipleCall == false) {
      preventMultipleCall = true;
      onBottomScroll();
      setTimeout(() => {
        preventMultipleCall = false;
      },2000);
    }
  };

  const handleRequestSort = (event: AnyObject, property: any) => {
    if (onSortChange) {
      onSortChange(property, order === 'asc' ? 'desc' : 'asc');
    }
  };

  return (
    <>
      <CustomPaper className={classes.root} elevation={elevation ? 1 : 0} style={customStyle}>
        <TableContainer className={`${classes.tableContainer}`} style={customStyle} onScroll={handleScroll}>
          <Table stickyHeader aria-label="sticky table">
            {!hideHeader && <EnhancedTableHead headCells={columns} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={!rows ? 0 : rows.length} />}
            <TableBody>
              {!rows
                ? []
                : rows.map((row: any,idx:number) => (
                  <TableRowStyles
                      hover={hover}
                      role="checkbox"
                      tabIndex={-1}
                      key={`table_row_styles_${idx}`}
                      onClick={onRowClick ? () => onRowClick(row) : undefined}
                      style={{ ...row.customStyles, ...(onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }) }}
                    >
                      <>
                        {columns
                          .filter((column) => {
                            const value = row[column.id];
                            if (column.hide === undefined) {
                              return true;
                            }
                            return typeof column.hide === 'boolean' ? !column.hide : !column.hide(value, row);
                          })
                          .map((column: any, idx:number) => {
                            const value = _.get(row, column.id);
                            return (
                              <TableCell key={`table_cell_${idx}`} align={column.align} style={{ ...column.customStyles }}>
                                <Typography component="span" className={cx(customClassnameTypography, classes.typography)}>
                                  {column.render ? column.render(value, row) : value}
                                </Typography>
                              </TableCell>
                            );
                          })}
                      </>
                    </TableRowStyles>
                  ))}
              {working ? (
                Array.from({ length: 3 }).map((item,idx) => (
                  <TableRowStyles key={`table_row_styles_${idx}`} hover={hover} role="checkbox" tabIndex={-1}>
                    {columns.map((column, idx) => (
                      <TableCell key={`table_cell_${idx}`}>
                        <Skeleton variant="text" />
                      </TableCell>
                    ))}
                  </TableRowStyles>
                ))
              ) : (
                <Fragment />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!(showCount && rows?.length) && (
          <div className={classes.countContainer}>
            {countTitle} {rows?.length}
          </div>
        )}
      </CustomPaper>
    </>
  );
};

export const InfoTable = React.memo(InfoTableRaw);
