import { ListMapping } from '../../../../application/screens/Private/ListMapping';
import { UpdateMapping } from '../../../../application/screens/Private/UpdateMapping';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'list-mappings',
    basePath: '/',
    target: {
      component: ListMapping,
      path: '/mappings',
    },
    enabled: true,
    icon: 'List',
    text: 'List Mapping',
    children: [],
    default: true,
  },
  {
    key: 'mappings-edit',
    basePath: '/',
    target: {
      component: UpdateMapping,
      path: '/mappings/:id/edit',
    },
    enabled: true,
    icon: 'List',
    text: 'Update Mapping',
    children: [],
    default: false,
  },
];
