import {FetchHttpService, HttpService, HttpServiceConfig} from '../HttpService';
import {MemoryTokenManager, RNTokenManager, TokenManager} from './TokenManager';
import {AnyObject} from '../../application/commons/types';

export interface SessionConfiguration {
    name: string;
    header: string;
}

export interface Configuration {
    sessions?: SessionConfiguration[];
}

export class StubbornHttpService {
    protected tokenManager: TokenManager = new MemoryTokenManager();
    protected sessionTokens: Map<string, string> = new Map<string, string>();
    protected sessionConfigurationsMap: Map<string, SessionConfiguration> = new Map<string, SessionConfiguration>();

    constructor(public service: HttpService, protected sessionConfigurations: SessionConfiguration[] = []) {
        sessionConfigurations.forEach(value => {
            this.sessionConfigurationsMap.set(value.name, value);
        });
    }

    async initialize() {
        for (const config of this.sessionConfigurationsMap.values()) {
            const token = await this.tokenManager.retrieve(config.name);
            if (token) {
                this.sessionTokens.set(config.name, token as string);
            }
        }
        this.setHeaders();
    }

    set TokenManager(manager: TokenManager) {
        this.tokenManager = manager;
    }

    get TokenManager() {
        return this.tokenManager;
    }

    async addSessionToken(name: string, token: string) {
        await this.tokenManager.persist(name, token);
        this.sessionTokens.set(name, token);
        this.setHeaders();
    }

    async removeSessionToken(name: string) {
        await this.tokenManager.clean(name);
        this.sessionTokens.delete(name);
        this.setHeaders();
    }

    async clearSessionTokens() {
        await this.tokenManager.cleanAll();
        this.sessionTokens.clear();
        this.cleanHeaders();
    }

    setHeaders() {
        const headers: AnyObject = {};
        for (const [name, token] of this.sessionTokens.entries()) {
            const config = this.sessionConfigurationsMap.get(name);
            if (!config) {
                throw new Error('Configr not found to set the headers');
            }
            headers[config.header] = token;
        }
        this.service.Headers = {...this.service.Headers, ...headers};
    }

    cleanHeaders() {
        this.service.Headers = {};
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static async create(type: 'fetch' = 'fetch', configuration: Partial<HttpServiceConfig> & Configuration) {
        const {sessions, ...rest} = configuration;
        const instance = new StubbornHttpService(new FetchHttpService(rest), sessions);
        instance.TokenManager = RNTokenManager.create();
        await instance.initialize();
        return instance;
    }
}
