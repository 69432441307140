import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Menu as MenuCis } from '../components/core/HeaderSimple/Menu/Menu';
import { Button, Drawer, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStubbornAuth } from '../../lib/StubbornService/react';
import { useProfileContext } from '../../lib/phinxapp/profiles/ProfileContext';
import { primary } from '../../theme/fonts/colors';

export const ResponsiveMenuLayout = ({ children }: { children: React.ReactNode }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchor, setAnchor] = React.useState({
    left: false,
  });
  const navigate = useNavigate();
  const SBAuth = useStubbornAuth();
  const { setProfile } = useProfileContext();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onLogout = () => {
    SBAuth.logout();
    setProfile('public');
  };

  const onOpenProfile = () => {
    navigate(`/my-account`);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu">
              <Button onClick={() => setAnchor({ left: true })}>
                <MenuIcon style={{ color: primary.white }} />
              </Button>
              <React.Fragment>
                <Drawer anchor="left" open={anchor['left']} onClose={() => setAnchor({ left: false })}>
                  <Box sx={{ width: 250, padding: '20px 20px' }} role="presentation" onClick={() => setAnchor({ left: false })} onKeyDown={() => setAnchor({ left: false })}>
                    <MenuCis />
                  </Box>
                </Drawer>
              </React.Fragment>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              CIS | BackOffice
            </Typography>
            <div>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={onOpenProfile}>Mi Perfil</MenuItem>
                <MenuItem onClick={onLogout}>Cerrar Sesion</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container style={{ marginTop: 10 }}>
        {children}
      </Grid>
    </>
  );
};
