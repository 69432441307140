import { Box, Grid, ThemeProvider } from '@mui/material';
import { useGetTransactionsService } from '../../../../services';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { buttonStyles } from '../../../../theme/styles';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';
import moment from 'moment';
import { statusTransaction, typeTransaction } from '../../../commons/hooks';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

export const Transactions = () => {
  const navigate = useNavigate();
  const { data, working: loading, callCustomers: callTransaction } = useGetTransactionsService({}, { autoCall: false });
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

  const transactions = data?.transactions.map((item) => ({
    transaction_id: item.transactionId,
    email: item.customer.email,
    company: item.company.name,
    transaction_type: typeTransaction(item),
    status: statusTransaction(item),
    created_at: moment(item.createdAt).format('D-MM-YYYY, HH:mm:ss'),
  }));

  const columnsTransaction: GridColDef[] = [
    { field: 'transaction_id', headerName: 'ID', flex: 1, editable: false },
    { field: 'email', headerName: 'Email', flex: 1, editable: false },
    { field: 'company', headerName: 'Compañía', flex: 1, editable: false },
    { field: 'transaction_type', headerName: 'Tipo de Transaccion', flex: 1, editable: false },
    { field: 'status', headerName: 'Estado', flex: 1, editable: false },
    { field: 'created_at', headerName: 'Creada', flex: 1, editable: false },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<PersonIcon />} label="Details" className="textPrimary" onClick={handleCustomersClick} color="inherit" />,
        ];
      },
    },
  ];

  const handleDatailsClick = (id: GridRowId) => () => {
    navigate(`/transactions/${id}`);
  };

  const handleCustomersClick = () => {
    navigate(
      `/customers`,
      // { state: { email: item.customer.email } }
    );
  };

  useEffect(() => {
    callTransaction({ limit: paginationModel.pageSize, offset: paginationModel.page, orderBy: '' });
  }, [paginationModel]);

  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Transacciones" />
          </Grid>
          <Grid item pb={4} sx={{ height: 700, width: '100%', '& .actions': { color: 'text.secondary' }, '& .textPrimary': { color: 'text.primary' } }}>
            <ThemeProvider theme={themeDataGrid}>
              <DataGrid
                localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                getRowId={(id) => id?.transaction_id}
                columns={columnsTransaction}
                rows={transactions || []}
                slots={{ toolbar: CustomToolbar }}
                loading={loading}
                rowCount={+(data?.transaction_count || 0)}
                pageSizeOptions={[10]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
