import {useMemo} from 'react';
import {useStubbornServerContext} from '../StubbornServerContext';
import {AnyObject, sessionChangeFunction} from '../../StubbornServer';

export const useStubbornAuth = () => {
    const {SBServer} = useStubbornServerContext();
    return useMemo(
        () => ({
            auth: <T extends AnyObject>(credentials: AnyObject, name = 'default') => SBServer.auth<T>(credentials, name),
            logout: () => SBServer.logout(),
            status: () => SBServer.status(),
            subscribe: (event: 'SessionChange', fn: sessionChangeFunction) => SBServer.subscribe(event, fn),
        }),
        [SBServer],
    );
};
