import { useStubbornServerContext } from '../StubbornServerContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AnyObject } from '../../StubbornServer';

export interface UseStubbornServiceOptions {
  autoCall?: boolean;
  throwError?: boolean;
}

export interface UseStubbornServiceReturn<T, S extends AnyObject> {
  data: T | null | undefined;
  working: boolean;
  error: string | null;
  statusCode: number | null;
  callCustomers: (params?: S) => Promise<T | null | undefined | { error: any }>;
  success: boolean;
}

const defaultOptions: UseStubbornServiceOptions = {
  autoCall: true,
  throwError: false,
};

export const useStubbornService = <T = any, S extends AnyObject = AnyObject>(name: string, params: S, options: UseStubbornServiceOptions = {}): UseStubbornServiceReturn<T, S> => {
  const optionsToUse = useRef<UseStubbornServiceOptions>({ ...defaultOptions, ...options });
  const defaultParams = useRef<S>(params);
  const [data, setData] = useState<T | null | undefined>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [working, setWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { SBServer } = useStubbornServerContext();
  const call = useCallback(
    async (newParams?: S) => {
      try {
        setWorking(true);
        setStatusCode(null);
        const paramsToBeUsed = newParams ? newParams : defaultParams.current;
        const response = await SBServer.callService<T, S>(name, paramsToBeUsed);
        setData(response.data);
        setStatusCode(response.statusCode);
        setWorking(false);
        setSuccess(true);
        return response.data;
      } catch (e) {
        const error = e as unknown as Error & { error?: string };
        const errorMessage = error.error || error.message;
        setError(errorMessage);
        setWorking(false);
        setSuccess(false);
        if (optionsToUse.current.throwError) {
          throw e;
        } else {
          return { error: e };
        }
      }
    },
    [SBServer, name, defaultParams],
  );

  useEffect(() => {
    if (optionsToUse.current.autoCall) {
      call();
    }
  }, [call]);

  return { data, working, callCustomers: call, error, statusCode, success };
};
