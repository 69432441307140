export const cisLogoImage = {
    width: '100%', 
    objectFit: 'contain', 
    cursor: 'pointer', 
}

export const searchTextFieldStyle = {
    borderRadius: 10, 
    fontSize: 14, 
}

export const gridContainerUserStyle = {
    width: '100%', 
    display: 'flex', 
    justifyContent: 'flex-end'
}

export const gridItemsUserStyle = {
    height: '100%', 
    display: 'flex', 
    paddingRight: 5 
}

export const userAvatarStyle = {
    marginLeft: 5
}

export const textAccountNameStyle = {
    marginLeft: 1, 
    fontSize: '18px', 
    fontWeight: 'bold'
}

export const textAccountEmailStyle = {
    marginLeft: 1, 
    fontSize: '13px'
}

export const collapseMenuOptionStyleStyle = { 
    height: 50 
}

export const menuOptionStyleStyle = { 
    boxShadow: 'none', 
    marginLeft: 3,
}