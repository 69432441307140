import * as React from "react"
import { SVGProps } from "react"

const UserIco = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.5}
    height={19.533}
    {...props}
  >
    <g transform="translate(.75 .783)">
      <path
        d="M16 6V4a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2"
        transform="translate(0 12)"
        style={{
          fill: "none",
          stroke: "#77838f",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: "1.5px",
        }}
      />
      <circle
        cx={4}
        cy={4}
        r={4}
        transform="translate(4)"
        style={{
          fill: "none",
          stroke: "#77838f",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: "1.5px",
        }}
      />
      <path
        data-name="Path"
        d="M3 5.87v-2A4 4 0 0 0 0 0"
        transform="translate(19 12.13)"
        style={{
          fill: "none",
          stroke: "#77838f",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: "1.5px",
        }}
      />
      <path
        data-name="Path"
        d="M0 0a4 4 0 0 1 3.008 3.875A4 4 0 0 1 0 7.75"
        transform="translate(15 .13)"
        style={{
          fill: "none",
          stroke: "#77838f",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: "1.5px",
        }}
      />
    </g>
  </svg>
)

export default UserIco
