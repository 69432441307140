import { ServicesFields } from '../../../../application/screens/Private/ServicesField';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'servicesFields',
    basePath: '/',
    target: {
      component: ServicesFields,
      path: '/cis-services-fields/create',
    },
    enabled: true,
    icon: 'List',
    text: 'Service Fields',
    children: [],
    default: true,
  },
];
