import { Box, Grid } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { UpdateFieldsForm } from './components';
import { useLocation } from 'react-router-dom';

export const UpdateFields: FunctionComponent = () => {
  const location = useLocation();
  return (
    <Box width="100%" height="100%" paddingX={4}>
      <Grid container rowSpacing={4} spacing={4}>
        <Grid item xs={8}>
          <HeaderTable title="Edición de campo" />
        </Grid>
        <Grid item xs={12}>
          {/*@ts-ignore */}
          <UpdateFieldsForm defaultParams={location.state.field} />
        </Grid>
      </Grid>
    </Box>
  );
};
