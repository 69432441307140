import { Alert, Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ButtonCIS } from '../../../../components/button';
import { CisService, useUpdateCisService } from '../../../../../services';
import { UpdateServiceTable } from './UpdateServicesTable';

export const UpdateServicesForm = ({ cisService }: { cisService?: CisService }) => {
  const { callCustomers: callUpdateCisService, error } = useUpdateCisService({});
  const [isOpen, setIsOpen] = useState(false);
  const [valueName, setValueName] = useState(cisService?.name);

  const callSendingData = async () => {
    try {
      await callUpdateCisService({ name: valueName, fields: [] });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={6}>
          <Typography width={'100px'} textAlign={'center'} variant="h6" sx={{ fontWeight: 'bold' }}>
            Nombre:
          </Typography>
          <TextField sx={{ width: 500 }} type={'string'} value={valueName} onChange={(e) => setValueName(e.target.value)} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <UpdateServiceTable cisService={cisService} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          {error ? (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                No se guardo correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Guardado correctamente
              </Alert>
            </Snackbar>
          )}
          <ButtonCIS typeButton="submit" textButton="Guardar" handleOnClick={callSendingData} />
        </Grid>
      </Grid>
    </>
  );
};
