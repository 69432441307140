import { privateMenu } from './privateMenu';
import {Profile} from '../../../lib/phinxapp/profiles/types';
import { MainLayout, MenuLayout } from '../../../application/layouts';
import { ResponsiveMenuLayout } from '../../../application/layouts/ResponsiveLayout';


export const privateProfile: Profile = {
    name: 'private',
    component: MainLayout,
    menu: privateMenu,
};
