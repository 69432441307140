import { ListMapping } from '../../../../application/screens/Private/ListMapping';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const listMappingMenu: MenuItem = {
  key: 'listMappingMenu',
  basePath: '/mappings',
  target: { path: '/', component: ListMapping },
  enabled: true,
  icon: 'List',
  text: 'List Mapping',
  children: routes,
  default: true,
};
