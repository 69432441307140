import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import { FormRenderProps } from 'react-final-form-hooks';
import { Template } from '../types';

export const FormContext = createContext<FormRenderProps<any>>({} as any);

export const useFormContext = <T>(): FormRenderProps<T> =>
  useContext<FormRenderProps<T>>(FormContext);

export type TemplateContextValue = {
  template: Template;
  setTemplate: Dispatch<SetStateAction<Template>>;
};

export const TemplateContext = createContext<TemplateContextValue>({} as TemplateContextValue);

export const useTemplateContext = () => useContext<TemplateContextValue>(TemplateContext);
