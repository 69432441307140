import { ListServices } from '../../../../application/screens/Private/ListServices';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const listServicesMenu: MenuItem = {
  key: 'listServicesMenu',
  basePath: '/cis-services',
  target: { path: '/', component: ListServices },
  enabled: true,
  icon: 'List',
  text: 'List Services',
  children: routes,
  default: true,
};
