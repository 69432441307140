import {ExecuteOptions, ExecuteResponse, HttpService, METHOD} from './HttpService';

export class FetchHttpService extends HttpService {
    async doCall<T>(method: METHOD, url: string, options?: ExecuteOptions): Promise<ExecuteResponse<T>> {
        let response: Response | undefined;
        try {
            const fetchOptions = {
                method,
                body: options?.body ? JSON.stringify(options.body) : undefined,
                headers: options?.headers ? {...this.config.headers, ...options.headers} : this.config.headers || {},
            };
            fetchOptions.headers['Content-Type'] = 'application/json';
            //@ts-ignore
            response = await fetch(this.config.baseUrl + url, fetchOptions);
            const data = await response.text();
            if (response.status < 200 || response.status >= 300) {
                // eslint-disable-next-line
        throw data;
            }
            return {
                data: JSON.parse(data),
                statusCode: response.status,
                //@ts-ignore
                headers: response.headers,
            };
        } catch (e) {
            if (response) {
                throw new Error(JSON.stringify({
                    data: null,
                    error: e,
                    statusCode: response.status,
                    headers: response.headers,
                }));
            }
            throw e;
        }
    }
}
