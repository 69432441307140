import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getDashboardStats';

export interface CustomersStats {
  total: number;
  last_week: number;
  last_month: number;
  by_month: number[];
  by_country: {
    country_id: string;
    country_name: string;
    country_iso_code: string;
    total: number;
  }[];
}

export interface TransactionsStats {
  total: number;
  last_week: number;
  last_month: number;
}

export interface DashboardStats {
  customers: CustomersStats;
  transactions: TransactionsStats;
}

export const useGetDashboardStats = (params: AnyObject, options: UseStubbornServiceOptions) => {
  return useStubbornService<DashboardStats>(SERVICE_ID, params, options);
};
