import { Box, Grid, ThemeProvider } from '@mui/material';
import { useGetCisServices, useDeleteCisMappingService } from '../../../../services';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { ButtonCIS } from '../../../components/button';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { buttonStyles } from '../../../../theme/styles';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

export const Services = () => {
  const { data, working } = useGetCisServices({}, { autoCall: true });
  const navigate = useNavigate();
  const [rows, setRows] = useState(data?.services);

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows?.filter((row) => row.cis_service_id !== id));
  };

  const handleDatailsClick = (id: GridRowId) => () => {
    setRows(data?.services.filter((row) => row.cis_service_id !== id));
    navigate(`/cis-services/${id}`);
  };
  const services = useMemo(
    () =>
      data?.services.map((item) => ({
        ...item,
      })),
    [data],
  );

  const columnsService: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nombre del servicio',
      flex: 1,
      editable: false,
    },
    {
      field: 'fields',
      headerName: 'Campos',
      flex: 1,
      editable: false,
    },
    {
      field: 'templates',
      headerName: 'Templates',
      flex: 1,
      editable: false,
    },
    {
      field: 'mappings',
      headerName: 'Mapeos',
      flex: 1,
      editable: false,
    },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} sx={{ color: 'red' }} />,
        ];
      },
    },
  ];

  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Servicios" />
            <Grid container>
              <Grid item xs={12} display={'flex'} gap={2}>
                <ButtonCIS
                  typeButton="button"
                  textButton="+ Crear servicio"
                  handleOnClick={() => {
                    navigate(`/cis-services/create`);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            pb={4}
            sx={{
              height: 700,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <ThemeProvider theme={themeDataGrid}>
              <DataGrid
                localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                getRowId={(id) => id?.cis_service_id}
                columns={columnsService}
                rows={services || []}
                slots={{
                  toolbar: CustomToolbar,
                }}
                loading={working}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
