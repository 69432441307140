import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const GET_CIS_SERVICE_FIELD_ID = 'getCisServiceFields';

export interface GetCisServicesFieldResponse {
  fields: CisServiceFields[];
}

export type CisServiceFields = {
  cis_service_field_id: string;
  cis_service_id: string;
  field_service_to: string;
  data_type: string;
  field_type: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
};

export interface GetCisServicesFieldRequest {
  cis_service_id: string;
}

export const useGetCisServicesFieldService = (params: GetCisServicesFieldRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<GetCisServicesFieldResponse>(GET_CIS_SERVICE_FIELD_ID, params, options);
};
