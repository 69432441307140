import { ServiceCreate } from '../../../../application/screens/Private/ServiceCreate';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const CisServicesCreateMenu: MenuItem = {
  key: 'cisServicesFieldsMenu',
  basePath: '/cis-services/create',
  target: { path: '/', component: ServiceCreate },
  enabled: true,
  icon: 'List',
  text: 'Cis Services Create',
  children: routes,
  default: true,
};
