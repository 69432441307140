import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const GET_CIS_SERVICES = 'getCisServices';

export interface GetCisServicesResponse {
  services: {
    cis_service_id: string;
    name: string;
    created_at: Date;
    updated_at: Date;
    fields?: number | null;
    templates?: number | null;
    mappings?: number | null;
  }[];
}

export const useGetCisServices = (params = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<GetCisServicesResponse>(GET_CIS_SERVICES, params, options);
};
