import { AnyObject } from '../application/commons/types';
import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const SERVICE_ID = 'getEventLogs';

export interface GetEventLogsRequest {
  sort?: string;
}

export interface EventLog {
  event_log_id: string;
  event_name: string;
  event_type: string;
  event_detail: Record<string, AnyObject>;
  created_at: Date;
  updated_at: Date;
}

export interface GetEventLogsResponse {
  events: EventLog[];
}

export const useGetEventLogs = (params: GetEventLogsRequest | Record<string, never> = {}, options: UseStubbornServiceOptions) => {
  return useStubbornService<GetEventLogsResponse>(SERVICE_ID, params, options);
};
