import { Box, Grid } from '@mui/material';
import { useGetCisMappingService, useDeleteCisMappingService } from '../../../../services';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGroupCIS } from '../../../components/button-group';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { InfoTable } from '../../../components/core/InfoTable';
import { columns } from './utils/column';
import { ButtonCIS } from '../../../components/button';

export const ListMapping = () => {
  const { id } = useParams();
  const { callCustomers: callDeleteCisMapping, working: loading } = useDeleteCisMappingService({});
  const { data, working } = useGetCisMappingService({ cis_service_id: id }, { autoCall: true });
  const navigate = useNavigate();

  const optionsComponent = useCallback((user: any) => {
    return (
      <ButtonGroupCIS
        options={[
          {
            label: 'Seleccione una opción',
            disabled: loading,
            handle: () => {
              return null;
            },
          },
          {
            label: 'Editar',
            disabled: loading,
            handle: () => {
              navigate(`/mappings/${user.cis_field_map_id}/edit`, { state: { user } });
            },
          },
          {
            label: 'Eliminar',
            disabled: loading,
            handle: () => {
              callDeleteCisMapping({
                cis_field_map_id: user.cis_field_map_id,
                cis_service_id: user.cis_service_id,
                cis_service_field_id: user.cis_service_field_id,
                cis_service_form_template_id: user.cis_service_form_template_id,
              });
            },
          },
        ]}
      ></ButtonGroupCIS>
    );
  }, []);

  const mapping = useMemo(
    () =>
      data?.cis_map.map((item) => ({
        ...item,
        operations: optionsComponent(item),
      })),
    [data, optionsComponent],
  );

  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Mapeos" />
            <ButtonCIS
              typeButton="button"
              textButton="+ Crear mapeo"
              handleOnClick={() => {
                navigate(`/mappings/create`);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoTable customStyle={{ height: '35rem', marginTop: 5 }} rowIdKey="map_id" columns={columns} rows={mapping || []} key="map_id" showCount={true} working={working} />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
