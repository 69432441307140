import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../assets/Loading';
import { useGetCustomerQuestionnaires } from '../../../../services/useGetCustomerQuestionnaires';

export const CustomerQuestionnaires = () => {
  const { id } = useParams();
  const { data, working } = useGetCustomerQuestionnaires({ customer_id: id }, { autoCall: true });
  const navigate = useNavigate();

  const openResume = (questionnaire_id: string) => {
    navigate(`/customers/${id}/questionnaires/${questionnaire_id}`);
  };

  return working ? (
    <Loading />
  ) : (
    <Grid container direction="column" p={5}>
      <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 28, marginBottom: 20 }}>Cuestionarios de cliente</Typography>
      {data?.questionnaires &&
        data?.questionnaires.map((item) => {
          return (
            <Accordion style={{ marginBottom: 10 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography gutterBottom style={{ fontWeight: 'bold', color: 'black', fontSize: 18 }}>
                  {item.questionnaire.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" style={{ marginTop: 10 }}>
                  <Grid item>
                    <Typography gutterBottom>Tipo: {item.questionnaire.questionnaire_type.type}</Typography>
                    <Typography gutterBottom>Estado: {item.questionnaire_status.status}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>Total de preguntas: {0}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>Siguiente Pregunta: {'-'}</Typography>
                    <Typography gutterBottom>Respuestas: {0}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Grid>
  );
};
