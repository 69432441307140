import React, { FunctionComponent, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { grey } from '../../../../theme/colors';
import { MainButton } from '../MainButton';

interface HeaderTablesTypesProps {
  title: string | JSX.Element;
  titleMainButton?: string;
  children?: JSX.Element;
  handleOnClick?(): void;
  renderLeft?(): ReactNode;
  renderRight?: () => ReactNode;
  onBack?: () => void;
}

export const HeaderTable: FunctionComponent<HeaderTablesTypesProps> = ({ title, titleMainButton, handleOnClick, children, renderLeft, renderRight, onBack }) => (
  <>
    <div style={{ width: '100%'}}>
      <Box display="flex" p={2} paddingX={0} alignItems="center" >
        <Box p={0} flexGrow={1} flexDirection="row" >
          {!renderLeft && (
            <Typography variant="h4" color={grey.medium} fontWeight="normal" align="left">
              {onBack && <ChevronLeft onClick={onBack} fontSize="small" cursor="pointer" />}
              {title}
            </Typography>
          )}
          {!!renderLeft && renderLeft()}
        </Box>

        <Box p={0}>
          {titleMainButton && <MainButton title={titleMainButton} handleOnClick={handleOnClick} />}
          {!!renderRight && renderRight()}
        </Box>
      </Box>
      {children && (
        <Box display="flex" p={1} paddingX={0} >
          {children}
        </Box>
      )}
    </div>
  </>
);
