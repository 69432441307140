import { Grid, MenuItem as MUIMenuItem, styled } from "@mui/material"

export const MenuItemCenter = styled(MUIMenuItem)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%'
})

export const Option = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '30px  auto'
})