import { ListServices } from '../../../../application/screens/Private/ListServices';
import { Services } from '../../../../application/screens/Private/Services';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { routes } from './routes';

export const ServicesMenu: MenuItem = {
  key: 'ServicesMenu',
  basePath: '/services',
  target: { path: '/', component: Services },
  enabled: true,
  icon: 'List',
  text: 'Services',
  children: routes,
  default: true,
};
