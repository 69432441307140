import { useEffect } from 'react';
import { useRouterContext } from '../../../lib/phinxapp/router';

export const useRouteScreenTitle = (title: string): void => {
  const { setMetadata } = useRouterContext();
  useEffect(() => {
    setMetadata((prev) => ({
      ...prev,
      title,
    }));
  }, [setMetadata, title]);
};
