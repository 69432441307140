import { Customers, CustomerDetails, CustomerQuestionnaires, CustomerQuestionnaireResume } from '../../../../application/screens/Private';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'customers',
    basePath: '/',
    target: {
      component: Customers,
      path: '/customers',
    },
    enabled: true,
    icon: 'List',
    text: 'Listado de Clientes',
    children: [],
    default: true,
  },
  {
    key: 'customer-details',
    basePath: '/',
    target: {
      component: CustomerDetails,
      path: '/customers/:id',
    },
    enabled: true,
    icon: 'List',
    text: 'Información',
    children: [],
    default: false,
  },
  {
    key: 'customer-questionnaires',
    basePath: '/',
    target: {
      component: CustomerQuestionnaires,
      path: '/customers/:id/questionnaires',
    },
    enabled: true,
    icon: 'List',
    text: 'Cuestionarios',
    children: [],
    default: false,
  },
  {
    key: 'customer-questionnaire-resume',
    basePath: '/',
    target: {
      component: CustomerQuestionnaireResume,
      path: '/customers/:customer_id/questionnaires/:questionnaire_id',
    },
    enabled: true,
    icon: 'List',
    text: 'Cuestionarios',
    children: [],
    default: false,
  },
];
