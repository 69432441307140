import { routes } from './routes';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';
import { Transactions } from '../../../../application/screens/Private';

export const transactionsMenu: MenuItem = {
  key: 'transactionsMenu',
  basePath: '/transactions',
  target: { path: '/', component: Transactions },
  enabled: true,
  icon: 'List',
  text: 'transactions',
  children: routes,
  default: true,
};
