import { routes } from './routes';
import {MenuItem} from '../../../../lib/phinxapp/profiles/types';

export const settingMenu: MenuItem = {
    key: 'settingMenu',
    basePath: '/setting',
    target:{path: '/'},
    enabled: true,
    icon: 'List',
    text: 'setting',
    children: routes,
    default: true,
};
