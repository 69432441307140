import { Box, Grid } from '@mui/material';
import { useGetCisServices, useDeleteCisMappingService } from '../../../../services';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroupCIS } from '../../../components/button-group';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { InfoTable } from '../../../components/core/InfoTable';
import { columns } from './utils/column';
import { ButtonCIS } from '../../../components/button';

export const ListServices = () => {
  const { data, working } = useGetCisServices({}, { autoCall: true });
  const navigate = useNavigate();

  const optionsComponent = useCallback((service: any) => {
    return (
      <ButtonGroupCIS
        options={[
          {
            label: 'Seleccione una opción',
            disabled: true,
            handle: () => {
              return null;
            },
          },
          {
            label: 'Detalles',
            disabled: working,
            handle: () => {
              navigate(`/cis-services/${service.cis_service_id}`);
            },
          },
          {
            label: 'Editar',
            disabled: working,
            handle: () => {
              navigate(`/cis-services/${service.cis_service_id}/edit`, { state: { service } });
            },
          },
          {
            label: 'Eliminar',
            disabled: working,
            handle: () => {
              return null;
            },
          },
        ]}
      ></ButtonGroupCIS>
    );
  }, []);

  const services = useMemo(
    () =>
      data?.services.map((item) => ({
        ...item,
        operations: optionsComponent(item),
      })),
    [data, optionsComponent],
  );
  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Servicios" />
            <Grid container>
              <Grid item xs={12} display={'flex'} gap={2}>
                <ButtonCIS
                  typeButton="button"
                  textButton="+ Crear servicio"
                  handleOnClick={() => {
                    navigate(`/cis-services/create`);
                  }}
                />
                <ButtonCIS
                  typeButton="button"
                  textButton="+ Crear campo"
                  handleOnClick={() => {
                    navigate(`/cis-services-fields/create`);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoTable customStyle={{ height: '35rem', marginTop: 5 }} rowIdKey="service_id" columns={columns} rows={services || []} key="service_id" showCount={true} working={working} />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
