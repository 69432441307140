import React, { useContext } from 'react';
import { ProfileContext as ProfileContextType } from './types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ProfileContext = React.createContext<ProfileContextType>({
    profile: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setProfile: () => {},
});

export const useProfileContext = () =>
    useContext<ProfileContextType>(ProfileContext);
