import { white, black, primary } from './colors';

type Pallet = { [k: string]: any };

const pallete: Pallet = {
  common: {
    black,
    white,
    neutral: '#e4e7eb',
    muted: '#9EA0A4',
    green: '#74a298',
    red: '#ff0000',
  },
  primary: {
    contrastText: white,
    main: primary.paleBlueStrong,
    light: '#F6F9FD',
    dark: primary.paleBlueStrong,
  },
  secondary: {
    contrastText: white,
    main: '#3e4f5e',
    light: '#3e4f5e',
    dark: '#34485e',
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
  },
  text: {
    primary: '#12161B',
    primaryAlternative: '#3C4858',
    secondary: '#66788A',
    disabled: '#A6B1BB',
    white,
    green: '#74a298',
    grey: '#b5bcc9',
  },
  background: {
    default: '#f8fafc',
    defaultDark: '#28343E',
    dark: '#394363',
    paper: white,
    greyF5: '#F5F5F5',
    blue: '#2D3367',
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
  orange: {
    main: '#EE744C',
  },
};

export default pallete;
