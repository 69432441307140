import { useStubbornService, UseStubbornServiceOptions } from '../lib/StubbornService/react';

const UPDATE_CIS_SERVICE_ID = 'updateCisService';

export interface UpdateCisServiceRequest {
  name: string;
  templateId: number[];
}

export interface UpdateCisServiceResponse {
  service: any;
}

export const useUpdateCisService = (params: UpdateCisServiceRequest | Record<string, never> = {}, options: UseStubbornServiceOptions = { autoCall: false }) => {
  return useStubbornService<UpdateCisServiceResponse>(UPDATE_CIS_SERVICE_ID, params, options);
};
