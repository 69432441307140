import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { Loading } from '../../../assets/Loading';
import { useGetLoggedUser } from '../../../../services/useGetLoggedUser';

export const MyAccount = () => {
  const { data, working } = useGetLoggedUser({}, { autoCall: false });

  return working ? (
    <Loading />
  ) : (
    <Grid container direction="column" p={5}>
      <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 28, marginBottom: 20 }}>My account</Typography>
      <Accordion style={{ marginBottom: 10 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20 }}>Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>User ID:</Typography>
              <Typography>{data?.bo_user_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Account ID:</Typography>
              <Typography>{data?.account_id}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black', marginLeft: 10 }}>Creado:</Typography>
              <Typography>{moment(data?.created_at).format('D-MM-YYYY')}</Typography>
            </Grid>
            <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>Nombre completo:</Typography>
              <Typography>{`${data?.first_name} ${data?.last_name}`}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ marginBottom: 10 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: 18, marginBottom: 20 }}>Change password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column"></Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
