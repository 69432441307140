import React, { useState } from 'react';
import { Avatar, Grid, IconButton, MenuItem, MenuList, Popover, Typography, useMediaQuery } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { CustomCollapse, GridCollapseMenuOptions, GridHeader, GridLayout, GridMenu, GridMenuIcon, GridMenuOptions, WhiteGrid } from './LayoutsStyles';
import { Menu } from '../components/core/HeaderSimple/Menu/Menu';
import { useStubbornAuth, useStubbornServerContext } from '../../lib/StubbornService/react';
import { logoCis } from '../assets/images';
import { useProfileContext } from '../../lib/phinxapp/profiles/ProfileContext';
import { cisLogoImage, collapseMenuOptionStyleStyle, menuOptionStyleStyle, textAccountEmailStyle, textAccountNameStyle } from './../../theme/styles/MenuLayoutStyles';

export const MenuLayout = ({ children }: { children: React.ReactNode }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<Element | null>(null);
  const SBAuth = useStubbornAuth();
  const { SBServer } = useStubbornServerContext();
  const session = SBServer.getSessionInfo('default');
  const { setProfile } = useProfileContext();
  const navigate = useNavigate();
  const ACCOUNT_NAME = `${session?.data?.account?.first_name || ''} ${session?.data?.account?.last_name || ''}`;

  const onLogout = () => {
    SBAuth.logout();
    setProfile('public');
  };

  const handleClosePopover = () => {
    setOpen(false);
    setAnchor(null);
  };

  const handleDropdownButton = (e: React.MouseEvent<Element, MouseEvent>) => {
    setOpen(true);
    setAnchor(e.currentTarget);
  };

  const onOpenProfile = () => {
    navigate(`/my-account`);
  };

  return (
    <GridLayout>
      <GridHeader>
        <GridMenuIcon onClick={() => navigate('/')}>
          <Box component="img" sx={cisLogoImage} alt="The cis latam logo" src={logoCis} />
        </GridMenuIcon>
        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Grid justifyContent="space-between" alignItems="center" style={{ height: '100%', display: 'flex', paddingRight: 30 }}>
            <Grid style={{ marginLeft: 5 }} container direction="row" justifyContent="center" alignItems="center">
              <Avatar />
              <Grid>
                <Typography sx={textAccountNameStyle}>{ACCOUNT_NAME}</Typography>
                <Typography sx={textAccountEmailStyle}>{session?.data?.account?.email || ''}</Typography>
              </Grid>
              <IconButton onClick={handleDropdownButton}>
                <ArrowDropDown />
              </IconButton>
              <Popover anchorEl={anchor} onClose={handleClosePopover} open={open}>
                <MenuList>
                  <MenuItem onClick={onOpenProfile}>Mi Perfil</MenuItem>
                  <MenuItem onClick={onLogout}>Cerrar sesion</MenuItem>
                </MenuList>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </GridHeader>
      <GridMenu>
        <Grid>
          <CustomCollapse collapsedSize={100} in={true}>
            <WhiteGrid elevation={1}>
              <GridCollapseMenuOptions sx={collapseMenuOptionStyleStyle} />
              <GridMenuOptions sx={menuOptionStyleStyle}>
                <Menu />
              </GridMenuOptions>
            </WhiteGrid>
          </CustomCollapse>
        </Grid>
        <Grid>{children}</Grid>
      </GridMenu>
    </GridLayout>
  );
};
