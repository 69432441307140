import React from 'react';

interface LoadingProps {
  viewBox?: string;
  className?: string;
  width?: string;
  height?: string;
  stroke?: string;
}

function LoadingComponent({ className = '', viewBox = '0 0 700 100', height = '174px', width = '174px', stroke = '#fdfdfd' }: LoadingProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`m-auto bg-transparent block width-14 height-14 ${className}`} width={width} height={height} viewBox={viewBox} preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke={stroke} strokeWidth="9" r="38" strokeDasharray="179.0707812546182 61.690260418206066">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8928571428571428s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );
}

export const Loading = React.memo(LoadingComponent);
