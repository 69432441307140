import { routes } from './routes';
import {MenuItem} from '../../../../lib/phinxapp/profiles/types';
import { Dashboard } from '../../../../application/screens/Private/Dashboard';

export const dashboardMenu: MenuItem = {
    key: 'dashboardMenu',
    basePath: '/dashboard',
    target:{path: '/',component: Dashboard},
    enabled: true,
    icon: 'List',
    text: 'dashboard',
    children: routes,
    default: true,
};
