import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EventLog } from '../../../../../services/useGetEventLogs';

export const EventLogsTable = ({ events }: { events?: EventLog[] }) => {
  const columns: GridColDef[] = [
    {
      field: 'event_name',
      headerName: 'Nombre',
      width: 250,
    },
    {
      field: 'event_type',
      headerName: 'Tipo',
      width: 250,
    },
    {
      field: 'event_detail',
      headerName: 'Detalle',
      width: 350,
    },
    {
      field: 'created_at',
      headerName: 'Fecha',
      width: 220,
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid rows={events || []} columns={columns} components={{}} />
    </Box>
  );
};
