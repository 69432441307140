//@ts-nocheck
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Chip, Theme, useTheme } from '@mui/material';
import { MenuProps } from './utils/MenuProps';

type SelectOption = {
  value: string | number;
  label: string;
};

type SelectFormProps = {
  value?: string | number | readonly string[] | undefined;
  handleChange?: (e: SelectChangeEvent<string | number | readonly string[]>, child: React.ReactNode) => void;
  label?: string;
  disabled?: boolean;
  options?: SelectOption[];
  required?: boolean;
  name?: string;
  multiple?: boolean;
};

function getStyles(value: string, label: readonly string[], theme: Theme) {
  return {
    fontWeight: label.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export const SelectForm = ({
  value = ``,
  handleChange = () => {
    return null;
  },
  label = ``,
  disabled = false,
  options = [],
  required = false,
  name = '',
  multiple = false,
}: SelectFormProps) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <FormControl sx={{ minWidth: 150 }} disabled={disabled} required={required}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          value={value}
          onChange={handleChange}
          label={label}
          name={name}
          sx={{ width: 500 }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip key={index} label={value.label} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option} style={getStyles(option.value, option.label, theme)} divider>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
