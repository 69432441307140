import { useCallback, useState, useEffect } from 'react';
import { useStubbornAuth } from './lib/StubbornService/react';
import { PhinxApp } from './lib/phinxapp/profiles/PhinxApp';
import { useProfileContext } from './lib/phinxapp/profiles/ProfileContext';
import { Container } from './application/commons/ui/Container';
import { Loading } from './application/assets/Loading';
import { Grid } from '@mui/material';

export const App = (): JSX.Element => {
  const SB = useStubbornAuth();
  const { setProfile } = useProfileContext();
  const [loading, setLoading] = useState(false);

  const onLoad = useCallback(async () => {
    try {
      setLoading(true);
      const status = await SB.status();
      if (Object.values(status.data || {}).length) {
        setProfile('private');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = useCallback(() => {
    return (
      <Grid container height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Loading viewBox="0 0 100 100" width="150px" height="150px" stroke="#FAC306" />
      </Grid>
    );
  }, []);

  return <PhinxApp onLoad={onLoad} loading={loading} render={render} />;
};
