import { Grid, styled } from '@mui/material';
import { useGetDashboardStats } from '../../../../services/useGetDashboardStats';
import { Loading } from '../../../assets/Loading';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { OverviewAnalytics } from './OverviewAnalytics';
import { OverviewStatistics } from './OverviewStatistics';

const LayoutContainer = styled(Grid)({
  display: 'grid',
  height: '100%',
  width: '100%',
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 50,
  overflow: 'auto',
});

const GoalsAndAnalitycsContainer = styled(Grid)({
  display: 'grid',
  gridTemplateRows: '80% 20%',
  height: '100%',
  width: '100%',
});

export const Dashboard = () => {
  useRouteScreenTitle('User Manager');
  const { data, working } = useGetDashboardStats({}, { autoCall: true });

  if (working) {
    return <Loading />;
  }

  return (
    <LayoutContainer>
      <OverviewStatistics stats={data} />
      <GoalsAndAnalitycsContainer>
        <OverviewAnalytics customers_stats={data?.customers} />
      </GoalsAndAnalitycsContainer>
    </LayoutContainer>
  );
};
