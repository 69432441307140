import React from 'react';
import { TextField as MaterialTextField, TextFieldProps as MaterialTextFieldProps } from '@mui/material';

export type TextFieldProps = MaterialTextFieldProps;

export const TextField = (props: TextFieldProps) => {
  const { inputProps, fullWidth = true, variant = 'outlined', ...rest } = props;

  return <MaterialTextField fullWidth={fullWidth} variant={variant} {...rest} inputProps={inputProps} />;
};
