import { EventLogs } from '../../../../application/screens/Private/EventLogs';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'events',
    basePath: '/',
    target: {
      component: EventLogs,
      path: '/events',
    },
    enabled: true,
    icon: 'List',
    text: 'Listado de Events',
    children: [],
    default: true,
  },
];
