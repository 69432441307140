import { Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { forwardRef, Fragment, useMemo, useState } from 'react';
import { useGetCisServices, useCreateCisServiceField } from '../../../../../services';
import { SelectForm } from '../../../../components/Select';
import { ButtonCIS } from '../../../../components/button/Button';
import { useForm, Controller } from 'react-hook-form';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ServicesFieldsForm = () => {
  const { data } = useGetCisServices({}, { autoCall: true });
  const { callCustomers: callCreateCisService, error } = useCreateCisServiceField({});
  const { control, handleSubmit } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const dataSelectedType = [
    { label: 'Request', value: 'request' },
    {
      label: 'Response',
      value: 'response',
    },
  ];

  const dataSelectedData = [
    { label: 'Boolean', value: 'boolean' },
    { label: 'Number', value: 'number' },
    {
      label: 'String',
      value: 'string',
    },
  ];

  const dataSelectedService = useMemo(
    () =>
      data?.services.map((item) => ({
        value: item.cis_service_id,
        label: item?.name,
      })),
    [data],
  );

  const selectedType = useMemo(
    () =>
      dataSelectedType.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    [dataSelectedType],
  );

  const selectedData = useMemo(
    () =>
      dataSelectedData.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    [dataSelectedData],
  );

  const [valueService, setValueService] = useState('');
  const [valueName, setValueName] = useState('');
  const [valueType, setValueType] = useState('');
  const [valueData, setValueData] = useState('');

  const onChangeService = (e: any) => {
    setValueService(e.target.value);
  };
  const onChangeName = (e: any) => {
    setValueName(e.target.value);
  };
  const onChangeType = (e: any) => {
    setValueType(e.target.value);
  };
  const onChangeData = (e: any) => {
    setValueData(e.target.value);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  const callSendingData = async () => {
    try {
      await callCreateCisService({
        cis_service_id: valueService,
        field_service_to: valueName,
        field_type: valueType,
        data_type: valueData,
      });
      setIsOpen(true);
    } catch (e: unknown) {
      console.error(e);
    }
  };

  return (
    <Fragment>
      <Grid container display={'flex'} flexDirection={'row'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Servicio:
          </Typography>
          <SelectForm multiple={false} value={valueService} options={dataSelectedService} handleChange={onChangeService} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Nombre:
          </Typography>
          <Controller control={control} name="controlName" render={({ field }) => <TextField {...field} type={'string'} value={valueName} onChange={onChangeName}></TextField>} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={2}>
          <Typography width={'100px'} textAlign={'center'}>
            Tipo:
          </Typography>
          <SelectForm multiple={false} value={valueType} options={selectedType} handleChange={onChangeType} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'} mb={4}>
          <Typography width={'100px'} textAlign={'center'}>
            Dato:
          </Typography>
          <SelectForm multiple={false} value={valueData} options={selectedData} handleChange={onChangeData} />
        </Grid>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          {error && (
            <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                No se guardo correctamente, intente nuevamente.
              </Alert>
            </Snackbar>
          )}
          <ButtonCIS typeButton="submit" textButton="Guardar" handleOnClick={handleSubmit(callSendingData)} />
        </Grid>
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Guardado correctamente
          </Alert>
        </Snackbar>
      </Grid>
    </Fragment>
  );
};
