import { MenuItem } from '../../../lib/phinxapp/profiles/types';
import { customersMenu } from './Customers';
import { dashboardMenu } from './Dashboard/dashboardMenu';
import { usersMenu } from './Users';
import { transactionsMenu } from './Transactions';
import { settingMenu } from './Setting';
import { listMappingMenu } from './ListMapping';
import { ServicesFieldsMenu } from './ServicesFields';
import { mappingCreateFormMenu } from './MappingCreate';
import { CisServicesCreateMenu } from './ServiceCreate';
import { listServicesMenu } from './ListServices';
import { ServicesMenu } from './Services';
import { eventsMenu } from './EventLogs';

export const routes: MenuItem[] = [
  dashboardMenu,
  usersMenu,
  customersMenu,
  transactionsMenu,
  settingMenu,
  listMappingMenu,
  listServicesMenu,
  mappingCreateFormMenu,
  ServicesFieldsMenu,
  CisServicesCreateMenu,
  ServicesMenu,
  eventsMenu,
];
