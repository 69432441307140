import { ServiceCreate } from '../../../../application/screens/Private/ServiceCreate';
import { MenuItem } from '../../../../lib/phinxapp/profiles/types';

export const routes: MenuItem[] = [
  {
    key: 'cisServicesCreate',
    basePath: '/',
    target: {
      component: ServiceCreate,
      path: '/cis-services/create',
    },
    enabled: true,
    icon: 'List',
    text: 'Cis Services Create',
    children: [],
    default: true,
  },
];
