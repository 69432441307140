import { Box, useMediaQuery, Grid, Typography, Card, Button } from '@mui/material';
import { Theme } from '@mui/system';
import { Loading } from '../../../../../assets/Loading';
import { FormInput, useFormContext } from '../../../../../components/templates';
import { LoginValues } from '../../Login';

export const LoginForm = ({ loading }: { loading: boolean }) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { form, errors, handleSubmit } = useFormContext<LoginValues>();

  return (
    <Box padding={isDownSm ? 3 : 6} width="100%">
      <Grid container direction="row" spacing={3}>
        <Grid item sx={{textAlign:'center'}} xs={12}>
          <Typography variant="h4" color="initial">
            Ingrese a su cuenta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            variant="outlined"
            inputLabelProps={{
              shrink: false,
            }}
            placeholder="Ingrese email"
            type="email"
            name="email"
            form={form}
            size="medium"
            id="email-1"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            variant="outlined"
            inputLabelProps={{
              shrink: false,
            }}
            placeholder="Contraseña"
            type="password"
            name="password"
            form={form}
            size="medium"
            id="password"
            autoComplete="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth onClick={() => handleSubmit()} disabled={Object.keys(errors as any).length > 0 || loading} size="large" variant="contained" color="primary">
            {loading ? <Loading viewBox="0 0 100 100" width="30px" height="30px" /> : 'Ingresar'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
